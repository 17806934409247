import { useEffect, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
    BarElement
} from 'chart.js';

// Register all necessary components
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend, BarElement);


const MatchGraph = ({ activeTabParent }) => {
    const [selectedTab, setSelectedTab] = useState("Run Graph");
    const { match_id } = useParams();
    const [OverGraphData, setOverGraphData] = useState([]);
    const [BallbyballData, setBallbyballData] = useState([]);
    const [inningsData, setInningsData] = useState([]);
    const [loading, setLoading] = useState(true);

    let intervalId;
    useEffect(() => {
        if (match_id && activeTabParent === 'Graph') {
            MatchBallByBall();
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [match_id, activeTabParent]);


    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const MatchBallByBall = () => {
        const dataString = { match_id: match_id };
        ApiService.postData('match/matchBallByBall', dataString).then((res) => {
            if (res.status === 'success' && res.matchOddHistoryData) {
                if (res.matchOddHistoryData && res.matchOddHistoryData.match_status == 3) {
                    getLiveGraph(res.matchOddHistoryData.match_api_id);
                    intervalId = setInterval(() => {
                        getLiveGraph(res.matchOddHistoryData.match_api_id);
                    }, 1000);
                    setLoading(false);
                } else if (res.matchOddHistoryData && res.matchOddHistoryData.match_status == 2) {
                    if (res.matchOddHistoryData.match_odd_history && res.matchOddHistoryData.match_odd_history.data) {
                        const matchData = res.matchOddHistoryData.match_odd_history.data;
                        const innings = Object.keys(matchData);
                        const allInningsData = innings.map((inningKey) => {
                            const inningData = matchData[inningKey].reverse();
                            let runs = 0;
                            return inningData.map(over => ({
                                over: over.team.over,
                                runs: runs += over.team.runs,
                                inning: inningKey
                            }));
                        });
                        const OverGrapgData = innings.map((inningKey) => {
                            const inningData = matchData[inningKey];
                            return inningData.map(over => ({
                                over: over.team.over,
                                runs: over.team.runs,
                                inning: inningKey
                            }));
                        });
                        const BallbyBallGraphData = innings.map((inningKey) => {
                            const inningData = matchData[inningKey];
                            return inningData.map(over => ({
                                over: over.team.over,
                                runs: over.overs[0].max_rate,
                                inning: inningKey
                            }));
                        });
                        setInningsData(allInningsData);
                        setOverGraphData(OverGrapgData);
                        setBallbyballData(BallbyBallGraphData);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
            }
        });
    };

    const getLiveGraph = (match_api_id) => {
        const formData = new FormData();
        formData.append('match_id', match_api_id);
        ApiService.postCricketData("matchOddHistoryV1/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        const matchData = res.data;
                        const innings = Object.keys(matchData);
                        const allInningsData = innings.map((inningKey) => {
                            const inningData = matchData[inningKey].reverse();
                            let runs = 0;
                            return inningData.map(over => ({
                                over: over.team.over,
                                runs: runs += over.team.runs,
                                inning: inningKey
                            }));
                        });
                        const OverGrapgData = innings.map((inningKey) => {
                            const inningData = matchData[inningKey];
                            return inningData.map(over => ({
                                over: over.team.over,
                                runs: over.team.runs,
                                inning: inningKey
                            }));
                        });
                        const BallbyBallGraphData = innings.map((inningKey) => {
                            const inningData = matchData[inningKey];
                            return inningData.map(over => ({
                                over: over.team.over,
                                runs: over.overs[0].max_rate,
                                inning: inningKey
                            }));
                        });
                        setInningsData(allInningsData);
                        setOverGraphData(OverGrapgData);
                        setBallbyballData(BallbyBallGraphData);
                        setLoading(false);
                    }
                }
            })
            .catch(() => {
            });
    };

    const colors = [
        '#FF9F40',
        '#FF6384',
        '#36A2EB',
        '#9966FF',
        '#4BC0C0',
        '#9966FF',
    ];

    const chartData = {
        labels: inningsData.length > 0 ? inningsData[0].map(overData => overData.over) : [],
        datasets: inningsData.map((inningData, index) => ({
            label: `Inning ${index + 1}`,
            data: inningData.map(overData => overData.runs),
            fill: false,
            backgroundColor: colors[index % colors.length], // Cycle through colors
            borderColor: colors[index % colors.length],
            borderWidth: 2,
        })),
    };

    const barChartData = {
        labels: OverGraphData.length > 0 ? OverGraphData[0].map(overData => overData.over) : [],
        datasets: OverGraphData.map((inningData, index) => ({
            label: `Inning ${index + 1}`,
            data: inningData.map(overData => overData.runs),
            backgroundColor: colors[index % colors.length],
        })),
    };

    const ballchartData = {
        labels: BallbyballData.length > 0 ? BallbyballData[0].map(overData => overData.over) : [],
        datasets: BallbyballData.map((inningData, index) => ({
            label: `Inning ${index + 1}`,
            data: inningData.map(overData => overData.runs),
            fill: false,
            backgroundColor: colors[index % colors.length], // Cycle through colors
            borderColor: colors[index % colors.length],
            borderWidth: 2,
        })),
    };

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    const optionsball = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: false,
            },
        },
    };
    return (
        activeTabParent === 'Graph' && loading ? (
            <Skeleton width={950} height={500} />
        ) : (
            <>
                {activeTabParent === 'Graph' && (
                    <div>
                        <div className="section-title">
                            <h5 className="mb-0">Graph</h5>
                        </div>

                        <div className="cardBox p-15 mb-20">
                            <div className="inner-tabs">
                                <ul>
                                    <li onClick={() => handleTabClick("Run Graph")} className={selectedTab === "Run Graph" ? "active" : ""} >Run Graph</li>
                                    <li onClick={() => handleTabClick("Ball Graph")} className={selectedTab === "Ball Graph" ? "active" : ""} >Ball Graph</li>
                                    <li onClick={() => handleTabClick("Ball By Ball Graph")} className={selectedTab === "Ball By Ball Graph" ? "active" : ""} >Ball By Ball Graph</li>
                                </ul>
                            </div>
                        </div>

                        <div>
                            {
                                selectedTab === "Run Graph" ?
                                    <Line data={chartData} options={options} /> :
                                    selectedTab === "Ball Graph" ?
                                        <Bar data={barChartData} options={options} /> :
                                        selectedTab === "Ball By Ball Graph" ?
                                            <Line data={ballchartData} options={optionsball} /> : null}
                        </div>
                    </div>
                )}

            </>
        )
    )
}

export default MatchGraph
import { useEffect, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

const MatchCommentry = ({ activeTabParent }) => {
    const { match_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [commentaryData, setCommentaryData] = useState({});
    const [innings, setInnings] = useState([]);
    const [selectedInning, setSelectedInning] = useState(null);
    const [selectedTab, setSelectedTab] = useState("All");
    const [DefaultInning, setDefaultInning] = useState([]);

    let intervalId;
    useEffect(() => {
        if (match_id && activeTabParent === 'Commentry') {
            matchCommentry();
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [match_id, activeTabParent]);

    const matchCommentry = () => {
        const dataString = { match_id: match_id };
        ApiService.postData('match/matchCommentry', dataString).then((res) => {
            if (res.status === 'success' && res.matchCommentaryData) {
                if (Number(res.matchCommentaryData.match_status) == 3) {
                    getLiveCommentary(res.matchCommentaryData.match_api_id);
                    intervalId = setInterval(() => {
                        getLiveCommentary(res.matchCommentaryData.match_api_id);
                    }, 1000);
                } else if (Number(res.matchCommentaryData.match_status) == 2) {
                    const commentaryData = res.matchCommentaryData.match_commentary.data;
                    const innings = Object.keys(commentaryData);
                    setSelectedInning(innings.length > 0 ? innings[0] : null);
                    setDefaultInning(Object.keys(commentaryData));
                    setInnings(innings.reverse());
                    setCommentaryData(commentaryData);

                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const getLiveCommentary = (match_api_id) => {        
        const formData = new FormData();
        formData.append('match_id', match_api_id);
        ApiService.postCricketData("commentary/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        const commentaryData = res.data;
                        const innings = Object.keys(commentaryData);
                        setSelectedInning(innings.length > 0 ? innings[0] : null);
                        setDefaultInning(Object.keys(commentaryData));
                        setInnings(innings.reverse());
                        setCommentaryData(commentaryData);
                    }
                }
            })
            .catch(() => {
            });
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const renderCommentary = (comments) => {
        return comments.map((comment, index) => (
            <div key={index}>
                {comment.type === 2 ? (
                    <div className="comentrybox-header-bottom">
                        <ul className="chblist">
                            <li className="text-center">
                                <h6 className="fw700 mb-1">{comment.data.over}</h6>
                                <p className="mb-0 tx-primary">End of over:</p>
                            </li>
                            <li className="text-center">
                                <h6 className="fw700 mb-1">{comment.data.runs}</h6>
                                <p className="mb-0 tx-primary">Runs</p>
                            </li>
                            <li className="text-center">
                                <h6 className="fw700 mb-1">{comment.data.team} <span className="tx-primary">{comment.data.team_score}/{comment.data.team_wicket}</span></h6>
                                <p className="mb-0 tx-primary">Score after {comment.data.over} overs</p>
                            </li>
                            <li className="text-center">
                                <p className="mb-0">{comment.data.batsman_1_name} <span className="tx-primary">{comment.data.batsman_1_runs}({comment.data.batsman_1_balls})</span></p>
                                <p className="mb-0">{comment.data.batsman_2_name} <span className="tx-primary">{comment.data.batsman_2_runs}({comment.data.batsman_2_balls})</span></p>
                            </li>
                            <li className="text-center">
                                <p className="mb-0">{comment.data.bolwer_overs} - {comment.data.bolwer_maidens} - {comment.data.bolwer_runs} - {comment.data.bolwer_wickets}</p>
                                <p className="mb-0 tx-primary">{comment.data.bolwer_name}</p>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <div className="p-15">
                        <div className="comentry">
                            <div className="comentryovrun">
                                <span className="tx-13 tx-gray-500">{comment.data.overs}</span>
                                <span className={getRunClass(comment.data)}>{comment.data.wicket == 1 ? 'W' : comment.data.runs}</span>
                            </div>
                            <div className="comentrytext">
                                <h6 className="fw700"><span className="tx-primary">{comment.data.runs} Runs,</span> {comment.data.title}</h6>
                                {comment?.data?.description && <p className="mb-0 tx-gray-500">{comment?.data?.description}</p>}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ));
    };

    const renderWicketCommentary = (comments) => {
        return comments.map((comment, index) => (
            comment.type == 1 && comment.data.wicket === "1" ? ( // Filter for wickets
                <div className="p-15" key={index}>
                    <div className="comentry">
                        <div className="comentryovrun">
                            <span className="tx-13 tx-gray-500">{comment.data.overs}</span>
                            <span className="ovlisy-over ovw">W</span>
                        </div>
                        <div className="comentrytext">
                            <h6 className="fw700"><span className="tx-primary">Out,</span> {comment.data.title}</h6>
                            {comment?.data?.description && <p className="mb-0 tx-gray-500">{comment?.data?.description}</p>}
                        </div>
                    </div>
                </div>
            ) : null // Return null if not a wicket
        ));
    };

    const renderFourCommentary = (comments) => {
        return comments.map((comment, index) => (
            comment.type == 1 && comment.data.runs === "4" ? ( // Filter for wickets
                <div className="p-15" key={index}>
                    <div className="comentry">
                        <div className="comentryovrun">
                            <span className="tx-13 tx-gray-500">{comment.data.overs}</span>
                            <span className="ovlisy-over ov4">4</span>
                        </div>
                        <div className="comentrytext">
                            <h6 className="fw700"><span className="tx-primary">{comment.data.runs} Runs, </span> {comment.data.title}</h6>
                            {comment?.data?.description && <p className="mb-0 tx-gray-500">{comment?.data?.description}</p>}
                        </div>
                    </div>
                </div>
            ) : null // Return null if not a wicket
        ));
    };

    const renderSixCommentary = (comments) => {
        return comments.map((comment, index) => (
            comment.type == 1 && comment.data.runs === "6" ? ( // Filter for wickets
                <div className="p-15" key={index}>
                    <div className="comentry">
                        <div className="comentryovrun">
                            <span className="tx-13 tx-gray-500">{comment.data.overs}</span>
                            <span className="ovlisy-over ov6">6</span>
                        </div>
                        <div className="comentrytext">
                            <h6 className="fw700"><span className="tx-primary">{comment.data.runs} Runs, </span> {comment.data.title}</h6>
                            {comment?.data?.description && <p className="mb-0 tx-gray-500">{comment?.data?.description}</p>}
                        </div>
                    </div>
                </div>
            ) : null // Return null if not a wicket
        ));
    };

    const renderOverCommentary = (comments) => {
        const type1Comments = comments.filter(comment => comment.type === 1);
        return comments.map((comment, index) => (
            comment.type == 2 ? ( // Filter for wickets
                <div className="cardBox mb-15" key={index}>
                    <div className="cmoverbox">
                        <div className="cmoverbox-header">
                            <div className="cmoverbox-overbox">
                                <h6>{comment.data.over} OVER</h6>
                                <p className="mb-0 tx-primary">{comment.data.runs} Runs</p>
                            </div>
                            <div className="cmoverbox-ovdetails">
                                <p>{comment.data.bolwer_name} to {comment.data.batsman_1_name} & {comment.data.batsman_2_name}</p>
                                <ul className="ovlisy">
                                    {type1Comments.map((type1Comment, ballIndex) => (
                                        <li key={ballIndex}>
                                            <div className={getRunClass(type1Comment.data)}>
                                                {type1Comment.data.wicket == 1 ? 'W' : type1Comment.data.runs}
                                            </div>
                                            <span className="tx-12 tx-gray-500">{type1Comment.data.overs}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="cmoverbox-bottom">
                            <ul className="chblist">

                                <li className="text-center">
                                    <h6 className="fw700 mb-1">{comment.data.team} <span className="tx-primary">{comment.data.team_score}/{comment.data.team_wicket}</span></h6>
                                    <p className="mb-0 tx-primary">Score after {comment.data.over} overs</p>
                                </li>
                                <li className="text-center">
                                    <p className="mb-0">{comment.data.batsman_1_name} <span className="tx-primary">{comment.data.batsman_1_runs}({comment.data.batsman_1_balls
                                    })</span></p>
                                    <p className="mb-0">{comment.data.batsman_2_name} <span className="tx-primary">{comment.data.batsman_2_runs}({comment.data.batsman_2_balls
                                    })</span></p>

                                </li>
                                <li className="text-center">
                                    <h6 className="fw700 mb-1">{comment.data.bolwer_overs} - {comment.data.bolwer_maidens} - {comment.data.bolwer_runs} - {comment.data.bolwer_wickets}</h6>
                                    <p className="mb-0 tx-primary">{comment.data.bolwer_name}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            ) : null // Return null if not a wicket
        ));
    };
    const getRunClass = (over) => {
        if (over.wicket == 1) {
            return "ovlisy-over ovw"
        } else {
            switch (over.runs) {
                case "6":
                    return "ovlisy-over ov6";   // 6 runs
                case "4":
                    return "ovlisy-over ov4";   // 4 runs
                case "2":
                    return "ovlisy-over ovrun"; // 2 runs
                case "WD":
                    return "ovlisy-over ovwd";  // Wide
                case "N":
                    return "ovlisy-over ovn";   // No ball
                default:
                    return "ovlisy-over ov"; // Default for other runs
            }
        }
    };

    return (
        activeTabParent === 'Commentry' && loading ? (
            <>
                <div>
                    <div className="section-title">
                        <Skeleton height={20} width={100} />
                    </div>
                    <div className="cardBox mb-30">
                        <div className="comentrybox">
                            <div className="comentrybox-header">
                                <div className="comentryTabs">
                                    <ul>
                                        <li><Skeleton width={30} /></li>
                                        <li><Skeleton width={30} /></li>
                                        <li><Skeleton width={30} /></li>
                                        <li><Skeleton width={30} /></li>
                                        <li><Skeleton width={30} /></li>
                                        <li><Skeleton width={50} /></li>
                                        <li><Skeleton width={50} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="comentrybox-header-bottom">
                                <ul className="chblist">
                                    <li className="text-center">
                                        <Skeleton height={24} width={50} />
                                        <p className="mb-0 tx-primary"><Skeleton width={80} /></p>
                                    </li>
                                    <li className="text-center">
                                        <Skeleton height={24} width={50} />
                                        <p className="mb-0 tx-primary"><Skeleton width={60} /></p>
                                    </li>
                                    <li className="text-center">
                                        <Skeleton height={24} width={60} />
                                        <p className="mb-0 tx-primary"><Skeleton width={150} /></p>
                                    </li>
                                    <li className="text-center">
                                        <Skeleton width={80} />
                                        <p className="mb-0"><Skeleton width={80} /></p>
                                    </li>
                                    <li className="text-center">
                                        <Skeleton height={24} width={80} />
                                        <p className="mb-0 tx-primary"><Skeleton width={100} /></p>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-15">
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <div className="comentry" key={index}>
                                        <div className="comentryovrun">
                                            <Skeleton height={20} width={40} />
                                            <Skeleton height={20} width={20} />
                                        </div>
                                        <div className="comentrytext">
                                            <Skeleton height={20} width={150} />
                                            <p className="mb-0 tx-gray-500">
                                                <Skeleton count={2} />
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : (

            activeTabParent === 'Commentry' && commentaryData && selectedInning && commentaryData[selectedInning] ?
                <div>

                    <div className="section-title">
                        <h5 className="mb-0">Commentry</h5>
                    </div>
                    <div className="cardBox mb-30">
                        <div className="comentrybox">
                            <div className="comentrybox-header">
                                <div className="comentryTabs">
                                    <ul>
                                        <li onClick={() => handleTabClick("All")} className={selectedTab === "All" ? "active" : ""}>All</li>
                                        <li onClick={() => handleTabClick("Over")} className={selectedTab === "Over" ? "active" : ""}>Over</li>
                                        <li onClick={() => handleTabClick("W")} className={selectedTab === "W" ? "active" : ""}>W</li>
                                        <li onClick={() => handleTabClick("4")} className={selectedTab === "4" ? "active" : ""}>4</li>
                                        <li onClick={() => handleTabClick("6")} className={selectedTab === "6" ? "active" : ""}>6</li>
                                        {innings.length > 0 && innings.map((inning, index) => (
                                            <li
                                                key={index}
                                                className={selectedTab === inning ? "active" : ""}
                                                onClick={() => handleTabClick(inning)}
                                            >
                                                {inning}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {selectedTab === "All" && selectedInning
                                ? Object.keys(commentaryData[selectedInning] || {}).map((over) =>
                                    renderCommentary(commentaryData[selectedInning][over] || [])
                                ) : selectedTab === "W"
                                    ? DefaultInning.map((inning) =>
                                        Object.keys(commentaryData[inning] || {}).map((over) =>
                                            renderWicketCommentary(commentaryData[inning][over] || [])
                                        )
                                    ) : selectedTab === "4"
                                        ? DefaultInning.map((inning) =>
                                            Object.keys(commentaryData[inning] || {}).map((over) =>
                                                renderFourCommentary(commentaryData[inning][over] || [])
                                            )
                                        ) : selectedTab === "6"
                                            ? DefaultInning.map((inning) =>
                                                Object.keys(commentaryData[inning] || {}).map((over) =>
                                                    renderSixCommentary(commentaryData[inning][over] || [])
                                                )
                                            ) : selectedTab && commentaryData[selectedTab]
                                                ? Object.keys(commentaryData[selectedTab] || {}).map((over) =>
                                                    renderCommentary(commentaryData[selectedTab][over] || [])
                                                )
                                                : null
                            }
                        </div>
                    </div>
                    {selectedTab === "Over"
                        ? DefaultInning.map((inning) =>
                            Object.keys(commentaryData[inning] || {}).map((over) =>
                                renderOverCommentary(commentaryData[inning][over] || [])
                            )
                        ) : null}
                </div>

                : null
        )
    );
};

export default MatchCommentry;

import { useEffect, useState } from "react"
import { ApiService } from "../../../Component/Services/apiservices"
import constant from "../../../Component/Services/constant"
import Skeleton from 'react-loading-skeleton'
import { useParams } from "react-router-dom"
const MatchScorecard = ({ activeTabParent }) => {
    const { match_id } = useParams();
    const [matchScorecard, setMatchScorecard] = useState([])
    const [matchSquads, setMatchSquads] = useState({})
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0);
    let intervalId;
    useEffect(() => {
        if (match_id && activeTabParent === 'Scorecard') {
            matchSquad()
            getMatchDetail()
        } 
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [match_id, activeTabParent])

    const matchSquad = () => {
        const dataString = { match_id: match_id };
        ApiService.postData('match/matchSquad', dataString).then((res) => {
            if (res.status === 'success') {
                if (res.matchSquadsData && res.matchSquadsData.match_squads && res.matchSquadsData.match_squads.data) {
                    setMatchSquads(res.matchSquadsData.match_squads.data);
                }
            }
        })
    };

    const getMatchDetail = () => {
        const dataString = {
            match_id: match_id
        }
        ApiService.postData("match/matchScorecard", dataString).then((res) => {
            if (res.status === "success") {
                if (res.match_scorecard && res.match_scorecard.match_status == 3) {
                    getLiveScorecard(res.match_scorecard.match_api_id);
                    intervalId = setInterval(() => {
                        getLiveScorecard(res.match_scorecard.match_api_id);
                    }, 1000);
                } else if (res.match_scorecard && res.match_scorecard.match_status == 2) {
                    if (res.match_scorecard.match_scorecard && res.match_scorecard.match_scorecard.data?.scorecard) {
                        const newDataInnKey = [];
                        for (const overKey in res.match_scorecard.match_scorecard.data?.scorecard) {
                            newDataInnKey.push({
                                title: `${overKey} Inning`,
                                score: res.match_scorecard.match_scorecard.data?.scorecard[overKey],
                            });
                        }
                        setMatchScorecard(newDataInnKey);
                    }
                    setTimeout(() => {
                        setLoading(false)
                    }, 500);
                } else {

                }
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    } 

    const getLiveScorecard = (match_api_id) => {
        const formData = new FormData();
        formData.append('match_id', match_api_id);
        ApiService.postCricketData("scorecardByMatchId/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        if (res.data.scorecard) {
                            const newDataInnKey = [];
                            for (const overKey in res.data.scorecard) {
                                newDataInnKey.push({
                                    title: `${overKey} Inning`,
                                    score: res.data.scorecard[overKey],
                                });
                            }
                            setMatchScorecard(newDataInnKey);
                        }
                    }
                }
            })
            .catch(() => {
            });
    };

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleLink = (playerId) => {
        window.location.href = `/player-detail/${playerId}`;
    };

    const setCaptainPlayerType = (playerId) => {
        if (matchSquads?.team_a?.player) {
            const matchingItem = matchSquads.team_a.player.find(
                (item) => Number(item.player_id) === Number(playerId)
            );

            if (matchingItem) {
                if (Number(matchingItem.captain) > 0) {
                    return matchingItem.play_role === 'Wicketkeeper' ? '(C & wk)' : '(C)';
                } else {
                    return matchingItem.play_role === 'Wicketkeeper' ? '(wk)' : '';
                }
            } else {
                const matchingItemTeamB = matchSquads.team_b.player.find(
                    (item) => Number(item.player_id) === Number(playerId)
                );

                if (matchingItemTeamB) {
                    if (Number(matchingItemTeamB.captain) > 0) {
                        return matchingItemTeamB.play_role === 'Wicketkeeper' ? '(C & wk)' : '(C)';
                    } else {
                        return matchingItemTeamB.play_role === 'Wicketkeeper' ? '(wk)' : '';
                    }
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    const setImpactPlayerType = (playerId) => {
        if (matchSquads?.team_a?.player) {
            const matchingItem = matchSquads.team_a.player.find(
                (item) => Number(item.player_id) === Number(playerId)
            );

            if (matchingItem) {
                if (Number(matchingItem.impact) === 1) {
                    return 'yes';
                } else if (Number(matchingItem.impact) === 2) {
                    return 'no';
                } else {
                    return '';
                }
            } else {
                const matchingItemTeamB = matchSquads.team_b.player.find(
                    (item) => Number(item.player_id) === Number(playerId)
                );

                if (matchingItemTeamB) {
                    if (Number(matchingItemTeamB.impact) === 1) {
                        return 'yes';
                    } else if (Number(matchingItemTeamB.impact) === 2) {
                        return 'no';
                    } else {
                        return '';
                    }
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    return (
        <>
            {activeTabParent === 'Scorecard' && loading ?
                <div>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="cardBox p-15 mb-20">
                        <div className="inner-tabs">
                            <ul>
                                <li className="active"><span className="fw700"><Skeleton width={150} height={15} /></span></li>
                                <li><span className="fw700"><Skeleton width={150} height={15} /></span></li>
                            </ul>
                        </div>
                    </div>
                    {Array.from({ length: 2 }).map((_, index) => (
                        <div className="score-table-section mb-15" key={index}>
                            <table className="score-table">
                                <thead>
                                    <th className="tx-primary"><Skeleton width={150} height={15} /></th>
                                    <th className="text-left"><Skeleton width={150} height={15} /></th>
                                    <th className="text-left"><Skeleton width={150} height={15} /></th>
                                    <th className="text-left"><Skeleton width={150} height={15} /></th>
                                    <th className="text-left"><Skeleton width={150} height={15} /></th>
                                    <th className="text-left"><Skeleton width={150} height={15} /></th>
                                </thead>
                                <tbody>
                                    {Array.from({ length: 3 }).map((_, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p className="tx-primary mb-0"><Skeleton width={150} height={15} /></p>
                                                <span className="tx-12 tx-gray-500"><Skeleton width={150} height={15} /></span>
                                            </td>
                                            <td className="text-left"><Skeleton width={150} height={15} /></td>
                                            <td className="tx-gray-500 text-left"><Skeleton width={150} height={15} /></td>
                                            <td className="tx-gray-500 text-left"><Skeleton width={150} height={15} /></td>
                                            <td className="tx-gray-500 text-left"><Skeleton width={150} height={15} /></td>
                                            <td className="tx-gray-500 text-left"><Skeleton width={150} height={15} /></td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <p className="mb-0"><Skeleton width={80} height={15} /></p>
                                            <span className="tx-12 tx-gray-500"><Skeleton width={150} height={15} /></span>
                                        </td>
                                        <td className="text-left" colSpan={4}><Skeleton width={150} height={15} /></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
                :
                activeTabParent === 'Scorecard' && matchScorecard && matchScorecard.length > 0 ? (
                    <div>
                        <div className="section-title">
                            <h5 className="mb-0">Scorecard</h5>
                        </div>
                        <div className="cardBox p-15 mb-20">
                            <div className="inner-tabs">
                                <ul>
                                    {matchScorecard.map((scorecard, index) => (
                                        <li
                                            key={index}
                                            className={index === activeTab ? 'active' : ''}
                                            onClick={() => handleTabClick(index)}
                                        >
                                            <span className="fw700">{scorecard.score?.team?.name || ''}</span>
                                            <span className="ml-10">
                                                {scorecard.score?.team?.score || ''}/{scorecard.score?.team?.wicket || ''} ({scorecard.score?.team?.over || ''})
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {matchScorecard.map((scorecard, index) => (
                            index === activeTab && (
                                <div key={index}>
                                    {scorecard.score?.batsman?.length > 0 && (
                                        <div className="score-table-section mb-15">
                                            <table className="score-table">
                                                <thead>
                                                    <tr>
                                                        <th className="tx-primary">Batter</th>
                                                        <th className="text-left">R</th>
                                                        <th className="text-left">B</th>
                                                        <th className="text-left">4s</th>
                                                        <th className="text-left">6s</th>
                                                        <th className="text-left">SR</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scorecard.score.batsman.map((batsman, batsmanIndex) => (
                                                        <tr key={batsmanIndex}>
                                                            <td>
                                                                <p className="tx-primary mb-0" onClick={(e) => handleLink(batsman.player_id)} style={{ cursor: 'pointer' }}>{batsman.name} {setCaptainPlayerType(batsman.player_id)} {setImpactPlayerType(batsman.player_id) == 'yes' && (<span style={{ 'color': 'rgba(40, 179, 63, 1)' }}>IMP</span>)}</p>
                                                                <span className="tx-12 tx-gray-500">{batsman.out_by}</span>
                                                            </td>
                                                            <td className="text-left">{batsman.run}</td>
                                                            <td className="tx-gray-500 text-left">{batsman.ball}</td>
                                                            <td className="tx-gray-500 text-left">{batsman.fours}</td>
                                                            <td className="tx-gray-500 text-left">{batsman.sixes}</td>
                                                            <td className="tx-gray-500 text-left">{batsman.strike_rate}</td>
                                                        </tr>
                                                    ))}
                                                    <tr>
                                                        <td>
                                                            <p className="mb-0">Extras</p>
                                                        </td>
                                                        <td className="text-left" colSpan={5}>{scorecard.score.team.extras}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {scorecard.score?.bolwer?.length > 0 && (
                                        <div className="score-table-section mb-15">
                                            <table className="score-table">
                                                <thead>
                                                    <tr>
                                                        <th className="tx-primary">Bowler</th>
                                                        <th className="text-left">Ovr</th>
                                                        <th className="text-left">M</th>
                                                        <th className="text-left">R</th>
                                                        <th className="text-left">W</th>
                                                        <th className="text-left">ECO</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scorecard.score.bolwer.map((bowler, bowlerIndex) => (
                                                        <tr key={bowlerIndex}>
                                                            <td>
                                                                <p className="tx-primary mb-0" onClick={(e) => handleLink(bowler.player_id)} style={{ cursor: 'pointer' }}>{bowler.name} {setCaptainPlayerType(bowler.player_id)} {setImpactPlayerType(bowler.player_id) == 'yes' && (<span style={{ 'color': 'rgba(40, 179, 63, 1)' }}>IMP</span>)}</p>
                                                            </td>
                                                            <td className="text-left">{bowler.over}</td>
                                                            <td className="tx-gray-500 text-left">{bowler.maiden}</td>
                                                            <td className="tx-gray-500 text-left">{bowler.run}</td>
                                                            <td className="tx-gray-500 text-left">{bowler.wicket}</td>
                                                            <td className="tx-gray-500 text-left">{bowler.economy}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {scorecard.score?.fallwicket?.length > 0 && (
                                        <div className="score-table-section mb-15">
                                            <table className="score-table">
                                                <thead>
                                                    <tr>
                                                        <th className="tx-primary">Fall of Wickets</th>
                                                        <th className="text-left">Score</th>
                                                        <th className="text-left">Over</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scorecard.score.fallwicket.map((fall, fallIndex) => (
                                                        <tr key={fallIndex}>
                                                            <td>
                                                                <p className="tx-primary mb-0">{fall.player}</p>
                                                            </td>
                                                            <td className="text-left">{fall.score}</td>
                                                            <td className="text-left">{fall.over}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            )
                        ))}
                    </div>
                ) : null
            }

        </>
    )
}

export default MatchScorecard
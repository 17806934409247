import { useEffect, useRef, useState } from 'react';
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import { ApiService } from '../../Component/Services/apiservices';
import constant from '../../Component/Services/constant';
import TrendingPlayer from '../../Component/Elements/trending_player';
import CurrentSeries from '../../Component/Elements/current_series';
import LatestNews from '../../Component/Elements/latest_news';
import { Breadcrumb } from 'react-bootstrap'

const ICCWomenRankings = () => {
    const [rankingList, setRankingList] = useState([]);
    const [activeHeadingKey, setActiveHeadingKey] = useState('1'); // 1 for Teams, 2 for Batting, etc.
    const [activeSubHeadingKey, setActiveSubHeadingKey] = useState('1'); // 1 for ODI, 2 for Test, 3 for T20
    const [loading, setLoading] = useState(true);
    const didMountRef = useRef(true);

    const headingList = {
        1: 'Teams',
        2: 'Batting',
        3: 'Bowling',
        4: 'All Rounders'
    };

    const subHeadingList = {
        1: 'ODI',
        3: 'T20'
    };

    useEffect(() => {
        if (didMountRef.current) {
            fetchRankingData(1, 2); // Fetch default team ranking (ODI)
            didMountRef.current = false;
        }
    }, []);

    const fetchRankingData = (type, apiType) => {
        setLoading(true);
        const url = `rankingData/${type}/${apiType}`;
        ApiService.fetchData(url).then((res) => {
            setRankingList(res.data);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        });
    };

    const ChangeHeadingsTab = (k) => {
        let apiType = 1; // default for players
        let type = 1; // default type for ODI

        if (k == 1) { // Teams selected
            apiType = 2;
            type = 1; // default to Test for Teams
        } else {
            // Batting, Bowling, All Rounders
            if (k == 2) type = 10; // Batting
            else if (k == 3) type = 11; // Bowling
            else if (k == 4) type = 12; // All Rounders
        }

        fetchRankingData(type, apiType);
        setActiveHeadingKey(k);
        setActiveSubHeadingKey('1'); // Reset to default subheading (Test)
    };

    const ChangeSubHeadingsTab = (k) => {
        let apiType = 1; // default for players
        let type = 1; // default to Test

        if (activeHeadingKey == 1) {
            // Teams selected, keep team API and set type based on subheading
            apiType = 2;
            type = k; // k is directly the type for Test, ODI, T20
        } else {
            // For Batting, Bowling, All Rounders
            if (activeHeadingKey == 2) {
                if (k == 1) type = 10; // Batting - Test
                else if (k == 3) type = 13; // Batting - T20
            } else if (activeHeadingKey == 3) {
                if (k == 1) type = 11; // Bowling - Test
                else if (k == 3) type = 14; // Bowling - T20
            } else if (activeHeadingKey == 4) {
                if (k == 1) type = 12; // All Rounders - Test
                else if (k == 3) type = 15; // All Rounders - T20
            }
        }

        fetchRankingData(type, apiType);
        setActiveSubHeadingKey(k);
    };


    return (
        <>
            <Header />
            <div className="pageSubheader">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active href="/">Women's ICC Rankings</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-gap-small pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className='section-title mb-20'>
                            <h2 className="mb-0">Women's ICC Rankings</h2>
                            </div>
                            <div className="seriesTabs mb-20">
                                <ul>
                                    {Object.keys(headingList).map((key) => (
                                        <li
                                            key={key}
                                            className={activeHeadingKey == key ? "active" : ""}
                                            onClick={() => ChangeHeadingsTab(key)}
                                        >
                                            {headingList[key]}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="iccrankingbox">
                                <div className="iccrankingbox-header">
                                    <h6 className="mb-0">
                                    Women's {headingList[activeHeadingKey]} Ranking
                                    </h6>
                                    <div className="iccrankingbox-tabs">
                                        <ul>
                                            {Object.keys(subHeadingList).map((subKey) => (
                                                <li
                                                    key={subKey}
                                                    className={activeSubHeadingKey == subKey ? "active" : ""}
                                                    onClick={() => ChangeSubHeadingsTab(subKey)}
                                                >
                                                    {subHeadingList[subKey]}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <table className="iccranktable">
                                    <thead>
                                        <tr>
                                            <th>Rank</th>
                                            <th>{activeHeadingKey == 1 ? "Teams" : "Player Name"}</th>
                                            {activeHeadingKey != 1 ?<th>Team</th> : ''}
                                            <th className="text-right">Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan={activeHeadingKey == 1 ? "3" : "4"} className="text-center" style={{height:'300px', fontSize:'18px'}}>Loading...</td>
                                            </tr>
                                        ) : rankingList.length > 0 ? (
                                            rankingList.slice(0, 5).map((value, index) => (
                                                <tr key={index}>
                                                    <td>{value.pt_rank}</td>
                                                    <td>
                                                        <div className='icbbox'>
                                                            <div className='icbboxMedia'>
                                                            <img src={value.pt_image ? value.pt_image : constant.DEFAULT_IMAGE} /> 
                                                            </div>
                                                            <div className='icbboxContent'>
                                                            {value.pt_team || value.pt_name}
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                        </td>
                                                    {activeHeadingKey != 1 ?<td>{value?.pt_country}</td> : ''}
                                                    <td className="text-right">{value.pt_rating}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3" className="text-center" style={{height:'100px'}}>No Record Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-3">
                        <div className="seriesSidebar">
                                        <TrendingPlayer />
                                        <CurrentSeries />
                                        <LatestNews />
                                    </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ICCWomenRankings;

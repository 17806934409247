import { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import constant from "../../../Component/Services/constant";
import { useNavigate } from "react-router-dom"

const MatchSquad = ({activeTabParent}) => {
    const { match_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [PlayerLoading, setPlayerLoading] = useState(false);
    const [teamAData, setTeamAData] = useState({});
    const [teamBData, setTeamBData] = useState({});
    const [teamData, setTeamData] = useState({});
    const [playersData, setplayersData] = useState([]);
    const [groupedPlayers, setGroupedPlayers] = useState({});
    const [selectedTeam, setSelectedTeam] = useState('team_a'); // Default to team A
    const didMountRef = useRef(true);
    const navigate = useNavigate()

    useEffect(() => {
        if (match_id && activeTabParent === 'Squad') {
            matchSquad();
        }
    }, [match_id,activeTabParent]);

    const matchSquad = () => {
        const dataString = { match_id: match_id };
        ApiService.postData('match/matchSquad', dataString).then((res) => {
            if (res.status === 'success') {
                if (res.matchSquadsData && res.matchSquadsData.match_status == 3) {
                    if (res.matchSquadsData && res.matchSquadsData.match_squads && res.matchSquadsData.match_squads.data) {
                        const data = res.matchSquadsData.match_squads.data;
                        if (data.team_a && data.team_b) {
                            setTeamAData(data.team_a);
                            setTeamBData(data.team_b);
                            setTeamData(data.team_a); // Set Team A data by default
                            groupPlayersByRole(data.team_a.player); // Group players for Team A initially
                            setplayersData(data.team_a.player);
                        }
                    }
                } else {
                    if (res.matchSquadsData && res.matchSquadsData.match_squads && res.matchSquadsData.match_squads.data) {
                        const data = res.matchSquadsData.match_squads.data;
                        if (data.team_a && data.team_b) {
                            setTeamAData(data.team_a);
                            setTeamBData(data.team_b);
                            setTeamData(data.team_a); // Set Team A data by default
                            groupPlayersByRole(data.team_a.player); // Group players for Team A initially
                            setplayersData(data.team_a.player);
                        }

                    }
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const groupPlayersByRole = (players) => {
        const grouped = players.reduce((acc, player) => {
            const { play_role } = player;
            if (!acc[play_role]) {
                acc[play_role] = [];
            }
            acc[play_role].push(player);
            return acc;
        }, {});
        setGroupedPlayers(grouped);
    };

    const handleTeamClick = (team) => {
        setPlayerLoading(true);
        if (team === 'team_a') {
            setTeamData(teamAData);
            setplayersData(teamAData.player);
            groupPlayersByRole(teamAData.player);
        } else {
            setTeamData(teamBData);
            setplayersData(teamBData.player);
            groupPlayersByRole(teamBData.player);
        }
        setSelectedTeam(team);
        setTimeout(() => {
            setPlayerLoading(false);
            setLoading(false);
        }, 500);

    };
    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleLink = (playerId) => {
        window.location.href = `/player-detail/${playerId}`;
    };

    const setCaptainPlayerType = (squadData) => {
        if (squadData) {
            if (squadData.captain > 0) {
                return squadData.play_role === 'Wicketkeeper' ? '(C & wk)' : '(C)';
            } else {
                return squadData.play_role === 'Wicketkeeper' ? '(wk)' : '';
            }
        }
        return '';
    }

    const setImpactPlayerType = (squadData) => {
        if (squadData) {
            if (Number(squadData.impact) === 1) {
                return 'yes';
            } else if (Number(squadData.impact) === 2) {
                return 'no';
            } else {
                return '';
            }
        }
        return '';
    }

    const setPlayerPlayingDropType = (squadData) => {
        if (squadData) {
            if (Number(squadData.play_drop) === 1) {
                return 'yes';
            } else if (Number(squadData.play_drop) === 0) {
                return 'no';
            } else {
                return '';
            }
        }
        return '';
    }

    const setIntPlayerType = (squadData) => {
        if (squadData) {
            return squadData.international > 0;
        }
        return false;
    }

    function setImpactPlayerBackground(squadData) {
        const impactType = setImpactPlayerType(squadData);

        if (impactType === 'yes') {
            return 'rgba(40, 179, 63, 0.1)';
        } else if (impactType === 'no') {
            return 'rgba(179, 40, 40, 0.1)';
        } else {
            return '';
        }
    }

    return (
        <div>
            {loading && activeTabParent === 'Squad' ? (
                <div>

                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-4">
                            <div className="squad-sidebar">
                                {Array.from({ length: 2 }).map((_, index) => (
                                    <div className="squadbox" key={index}>
                                        <div className="squadboxMedia"><Skeleton width={50} height={50} /></div>
                                        <div className="squadboxContent">
                                            <h2 className="teamName"><Skeleton width={150} height={20} /></h2>
                                            <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={100} height={15} /></p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="squad-setion">
                                <div className="squad-setion-header">
                                    <div className="squadTeam">
                                        <div className="squadTeam-media"><Skeleton width={50} height={50} /></div>
                                        <div className="squadTeam-content">
                                            <h2 className="teamname"><Skeleton width={100} height={15} /></h2>
                                            <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={60} height={15} /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="squad-setion-body">
                                    {Array.from({ length: 4 }).map((_, index) => (
                                        <>
                                            <div className="squad-setion-title mb-15 mt-10">
                                                <h6><Skeleton width={100} height={20} /></h6>
                                            </div>
                                            <div className="row g-3">
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <div className="col-lg-6">
                                                        <div className="squadbox squadbox-squad">
                                                            <div className="squadboxMedia"><Skeleton width={50} height={50} /></div>
                                                            <div className="squadboxContent">
                                                                <h2 className="teamName"><Skeleton width={100} height={15} /></h2>
                                                                <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={100} height={15} /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                activeTabParent === 'Squad' && teamAData.name && teamBData.name ? (
                    <>
                        <div className="section-title">
                            <h5 className="mb-0">Squad</h5>
                        </div>
                        <div className="row g-3">
                            <div className="col-lg-4">
                                <div className="squad-sidebar">
                                    <div className={`squadbox ${selectedTeam === 'team_a' ? 'active' : ''}`} onClick={() => handleTeamClick('team_a')}>
                                        <div className="squadboxMedia">
                                            <img src={teamAData.flag} alt={teamAData.name} onError={handleImageError} />
                                        </div>
                                        <div className="squadboxContent">
                                            <h2 className="teamName">{teamAData.name}</h2>
                                            <p className="tx-gray-500 mb-0 tx-12">{teamAData?.player?.length} Players</p>
                                        </div>
                                    </div>
                                    <div className={`squadbox ${selectedTeam === 'team_b' ? 'active' : ''}`} onClick={() => handleTeamClick('team_b')}>
                                        <div className="squadboxMedia">
                                            <img src={teamBData.flag} alt={teamBData.name} onError={handleImageError} />
                                        </div>
                                        <div className="squadboxContent">
                                            <h2 className="teamName">{teamBData.name}</h2>
                                            <p className="tx-gray-500 mb-0 tx-12">{teamBData?.player?.length} Players</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {PlayerLoading ? (
                                <div className="col-lg-8">
                                    <div className="squad-setion">
                                        <div className="squad-setion-header">
                                            <div className="squadTeam">
                                                <div className="squadTeam-media"><Skeleton width={50} height={50} /></div>
                                                <div className="squadTeam-content">
                                                    <h2 className="teamname"><Skeleton width={100} height={15} /></h2>
                                                    <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={60} height={15} /></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="squad-setion-body">
                                            {Array.from({ length: 4 }).map((_, index) => (
                                                <>
                                                    <div className="squad-setion-title mb-15 mt-10">
                                                        <h6><Skeleton width={100} height={20} /></h6>
                                                    </div>
                                                    <div className="row g-3">
                                                        {Array.from({ length: 4 }).map((_, index) => (
                                                            <div className="col-lg-6">
                                                                <div className="squadbox squadbox-squad">
                                                                    <div className="squadboxMedia"><Skeleton width={50} height={50} /></div>
                                                                    <div className="squadboxContent">
                                                                        <h2 className="teamName"><Skeleton width={100} height={15} /></h2>
                                                                        <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={100} height={15} /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-lg-8">
                                    <div className="squad-setion">
                                        <div className="squad-setion-header">
                                            <div className="squadTeam">
                                                <div className="squadTeam-media">
                                                    <img src={teamData && teamData.flag ? teamData.flag : constant.DEFAULT_IMAGE} onError={handleImageError} alt={teamData && teamData.name ? teamData.name : 'Hero Live Line'}></img></div>
                                                <div className="squadTeam-content">
                                                    <h2 className="teamname">{teamData && teamData.name ? teamData.name : ''}</h2>
                                                    <p className="tx-gray-500 mb-0 tx-12">{playersData && playersData.length > 0 ? playersData.length : ''} Players</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="squad-setion-body">
                                            {Object.keys(groupedPlayers).map((role) => (
                                                <div key={role}>
                                                    <div className="squad-setion-title mb-15 mt-10">
                                                        <h6>{role} ({groupedPlayers[role]?.length})</h6>
                                                    </div>
                                                    <div className="row g-3">
                                                        {groupedPlayers[role].map((player) => (
                                                            <div className="col-lg-6" onClick={(e) => handleLink(player.player_id)} key={player.player_id}>
                                                                <div className="squadbox squadbox-squad"> {/* style={{ background: setImpactPlayerBackground(player) }} */}
                                                                    <div className="squadboxMedia">
                                                                        <img src={player.image} alt={player.name} onError={(e) => constant.DEFAULT_IMAGE} />
                                                                    </div>
                                                                    <div className="squadboxContent">
                                                                        <h2 className="teamName">{player.name} {setCaptainPlayerType(player)}
                                                                            {setImpactPlayerType(player) === 'yes' ? (
                                                                                <i className="ri-arrow-left-right-fill" style={{ color: 'rgba(40, 179, 63, 1)' }}></i>
                                                                            ) : setImpactPlayerType(player) === 'no' ? (
                                                                                <i className="ri-arrow-left-right-fill" style={{ color: 'rgba(179, 40, 40, 1)' }}></i>
                                                                            ) : null}

                                                                            {setPlayerPlayingDropType(player) === 'yes' ? (
                                                                                <i className="ri-arrow-up-line" style={{ color: 'rgba(40, 179, 63, 1)' }}></i>
                                                                            ) : setPlayerPlayingDropType(player) === 'no' ? (
                                                                                <i className="ri-arrow-down-line" style={{ color: 'rgba(179, 40, 40, 1)' }}></i>
                                                                            ) : null}

                                                                            {setIntPlayerType(player) && (<i className="ri-flight-takeoff-fill"></i>)}
                                                                        </h2>
                                                                        <p className="tx-gray-500 mb-0 tx-12">{player.play_role}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                ) : null
            )}
        </div>
    );
};

export default MatchSquad;

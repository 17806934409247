import { ApiService } from "../../../Component/Services/apiservices";
import { useEffect, useRef, useState } from "react"
import Skeleton from 'react-loading-skeleton'
const MatchPointsTable = ({matchDetail,activeTabParent}) => {
    const didMountRef = useRef(true)
    const [loading, setLoading] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [groupStatus, setGroupStatus] = useState(true);
    const [pointTable, setPointTable] = useState([]);

    useEffect(() => {
        if (matchDetail && matchDetail.match_series_id && matchDetail.match_series_id>0 ) {
            if (matchDetail.match_series_id && activeTabParent === 'Points Table') {
                getpointsTable(matchDetail.match_series_id)
            }
        }
    }, [matchDetail.match_series_id,activeTabParent])

    const getpointsTable = (series_id) => {
        setLoading(true);
        // Create a new FormData object and append the necessary data
        const formData = new FormData();
        formData.append('series_id', series_id);
        ApiService.postCricketData("groupPointsTable/", formData)
            .then((res) => {
                if (res.status) {
                    if(res.data){
                        const PointsTableData = Object.entries(res.data).map(([group, pointTable]) => ({
                            group,
                            pointTable,
                        }));
                        setPointTable(PointsTableData);
                        if(PointsTableData[0]?.group === 'A'){
                            setGroupStatus(false)
                        }
                        setSelectedGroup(PointsTableData[0]?.group);  // Set the first group as default
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const handleGroupClick = (group) => {
        setSelectedGroup(group);
    }; 
    return (
        <>
            {activeTabParent === 'Points Table' && loading ?
                <div>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="point-tablebox">
                        <div className="point-tablebox-header">
                            <div className="ptableTabs">
                                <ul>
                                    <li><Skeleton width={60} height={15} /></li>
                                    <li><Skeleton width={100} height={15} /></li>
                                </ul>
                            </div>
                        </div>
                        <table className="ptable">
                            <thead>
                                <th><Skeleton width={100} height={15} /></th>
                                <th><Skeleton width={100} height={15} /></th>
                                <th className="text-center"><Skeleton width={100} height={15} /></th>
                                <th className="text-center"><Skeleton width={100} height={15} /></th>
                                <th className="text-center"><Skeleton width={100} height={15} /></th>
                                <th className="text-center"><Skeleton width={100} height={15} /></th>

                            </thead>
                            <tbody>
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <tr>
                                        {Array.from({ length: 6 }).map((_, index) => (
                                            <td><Skeleton width={100} height={15} /></td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                activeTabParent === 'Points Table' && pointTable && pointTable.length > 0 ? (
                    <div>
                        <div className="section-title">
                            <h5 className="mb-0">Point Table</h5>
                        </div>
                        <div className="point-tablebox">
                            {groupStatus && (
                                <div className="point-tablebox-header">
                                <div className="ptableTabs">
                                    <ul>
                                        {pointTable && pointTable.map((groupObj, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleGroupClick(groupObj.group)}
                                                className={selectedGroup === groupObj.group ? "active" : ""}
                                            >
                                                {groupObj.group}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            )}
                            
                            <table className="ptable">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Team</th>
                                        <th className="text-center">Match</th>
                                        <th className="text-center">Win</th>
                                        <th className="text-center">Loss</th>
                                        <th className="text-center">NR</th>
                                        <th className="text-center">Pts</th>
                                        <th className="text-center">Net RR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedGroup && pointTable.length > 0 && pointTable.find(groupObj => groupObj.group === selectedGroup).pointTable.map((team, idx) => (
                                        <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>
                                                <img src={team.flag} alt={team.teams} width="20" height="20" style={{ marginRight: "5px" }} />
                                                {team.teams}
                                            </td>
                                            <td className="text-center">{team.P}</td>
                                            <td className="text-center" style={{ color: 'green' }}>{team.W}</td>
                                            <td className="text-center" style={{ color: 'red' }}>{team.L}</td>
                                            <td className="text-center">{team.NR}</td>
                                            <td className="text-center">{team.Pts}</td>
                                            <td className="text-center" style={{ color: team.NRR >= 0 ? 'green' : 'red' }}>{team.NRR}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : null
            }
        </>
    )
}

export default MatchPointsTable
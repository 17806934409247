import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataContext from "../../../Component/Elements/event_context";
import { ApiService } from "../../../Component/Services/apiservices";
import Skeleton from 'react-loading-skeleton'
import constant from "../../../Component/Services/constant";
const MatchLiveLine = ({ matchDetail,activeTabParent }) => {
    const { match_id } = useParams();
    const contextValues = useContext(DataContext);
    const [loading, setLoading] = useState(true)
    const [matchLiveData, setMatchLiveData] = useState({})
    const [liveLast4Overs, setLiveLast4Overs] = useState([])
    let intervalId;
    useEffect(() => {
        if (match_id && activeTabParent === 'Live Line') {
            getMatchDetail()
        }
    }, [match_id, activeTabParent])

    useEffect(() => {
        if (contextValues) {
            setMatchLiveData(contextValues.matchLiveData);
        }
    }, [contextValues])

    const getMatchDetail = () => {
        const dataString = {
            match_id: match_id
        }
        ApiService.postData("match/matchLive", dataString).then((res) => {
            if (res.status === "success") {
                if (res.match_live && Number(res.match_live.match_status) == 3) {
                    setMatchLiveData(contextValues.matchLiveData);
                } else if (res.match_live && Number(res.match_live.match_status) == 2) {
                    if (res.match_live.match_live && res.match_live.match_live.data) {
                        setMatchLiveData(res.match_live.match_live.data);
                    }
                }
                setLoading(false)
            } else {
                setLoading(false)
            }
            setLoading(false)
        })
    }

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const convertToFractional = (decimalOdds) => {
        if (decimalOdds > 0) {
            const fractionalOdds = decimalOdds - 1;
            const numerator = Math.round(fractionalOdds * 100);
            if (numerator < 0) {
                return '0';
            } else {
                return `${numerator}`;
            }
        } else {
            return '0';
        }
    };
    return (
        <>
            {activeTabParent === 'Live Line' && loading ? (
                <div>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="cardBox mb-30">
                                <div className="winingpos">
                                    <div>
                                        <h6 className="mb-0 tx-primary"><Skeleton width={150} height={15} /></h6>
                                    </div>
                                    <div className="d-flex align-items-start gap-2">
                                        <span className="tx-primary tx-10"><Skeleton width={30} height={15} /></span>
                                        <span className="fw700"><Skeleton width={30} height={15} /></span>
                                        <span className="back bhv"><Skeleton width={30} height={15} /></span>
                                        <span className="lay bhv"><Skeleton width={30} height={15} /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cardBox mb-30">
                                <div className="wininlayb">
                                    <span className="run"><Skeleton width={30} height={15} /></span>
                                    <span className="ball"><Skeleton width={30} height={15} /></span>
                                    <span className="over"><Skeleton width={30} height={15} /></span>
                                    <span className="lay"><Skeleton width={30} height={15} /></span>
                                    <span className="back"><Skeleton width={30} height={15} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="score-table-section mb-15">
                                <table className="score-table">
                                    <thead>
                                        <tr>
                                            <th className="tx-primary"><Skeleton width={100} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from({ length: 2 }).map((_, index) => (
                                            <tr key={index}>
                                                <td><Skeleton width={100} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="score-table-section mb-15">
                                <table className="score-table">
                                    <thead>
                                        <tr>
                                            <th className="tx-primary"><Skeleton width={100} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from({ length: 1 }).map((_, index) => (
                                            <tr key={index}>
                                                <td><Skeleton width={100} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="score-table-section mb-15">
                                <table className="score-table">
                                    <thead>
                                        <tr>
                                            <th className="tx-primary"><Skeleton width={100} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                            <th className="text-center"><Skeleton width={30} height={15} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from({ length: 2 }).map((_, index) => (
                                            <tr key={index}>
                                                <td><Skeleton width={100} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                                <td className="text-center"><Skeleton width={30} height={15} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="cardBoxBody  mb-30">
                                {[...Array(1)].map((_, index) => (
                                    <div className="hmatchbox" key={index}>
                                        <div className="hmatchbox-left">
                                            <div className="hmtmatch">
                                                <Skeleton circle width={40} height={40} />
                                                <div className="hmtmatchContent">
                                                    <h6 className="hmtmatch-name"><Skeleton width={30} height={15} /></h6>
                                                </div>
                                            </div>
                                            <div className="hmatchbox-right justify-content-end">
                                                <div className="hmtmatch">
                                                    <div className="hmtmatchContent tx-right">
                                                        <h6 className="hmtmatch-name"><Skeleton width={30} height={15} /></h6>
                                                    </div>
                                                    <Skeleton circle width={40} height={40} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
                :
                activeTabParent === 'Live Line' && matchLiveData ?
                    <div>
                        <div className="section-title">
                            <h5 className="mb-0">Live Line</h5>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="cardBox mb-30">
                                    <div className="winingpos">
                                        <div>
                                            <h6 className="mb-0 tx-primary">Winning Possibility</h6>
                                        </div>
                                        <div className="d-flex align-items-start gap-2">
                                            <span className="tx-primary tx-10">Fav</span>
                                            <span className="fw700">{matchLiveData.fav_team}</span>
                                            <span className="back bhv">{convertToFractional(matchLiveData.min_rate)}</span>
                                            <span className="lay bhv">{convertToFractional(matchLiveData.max_rate)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="cardBox mb-30">
                                    <div className="wininlayb">
                                        <span className="run">-Run</span>
                                        <span className="ball">-Ball</span>
                                        <span className="over">0 Over</span>
                                        <span className="lay">3.1</span>
                                        <span className="back">2.1</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="score-table-section mb-15">
                                    <table className="score-table">
                                        <thead>
                                            <tr>
                                                <th className="tx-primary">Batsman</th>
                                                <th className="text-center">R</th>
                                                <th className="text-center">B</th>
                                                <th className="text-center">4s</th>
                                                <th className="text-center">6s</th>
                                                <th className="text-center">SR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matchLiveData?.batsman?.length > 0 && matchLiveData.batsman.map(({ name, run, ball, fours, sixes, strike_rate, strike_status }, idx) => (
                                                <tr key={idx}>
                                                    <td>{name} {Number(strike_status) == 1 && (<img src={constant.BAT} style={{ width: '12px' }} />)}</td>
                                                    <td className="text-center">{run}</td>
                                                    <td className="text-center">{ball}</td>
                                                    <td className="text-center">{fours}</td>
                                                    <td className="text-center">{sixes}</td>
                                                    <td className="text-center">{strike_rate}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className="tx-primary">Partnership {matchLiveData?.partnership ? matchLiveData?.partnership.run + ' (' + matchLiveData?.partnership.ball + ')' : ''}</td>
                                                <td className="text-center tx-danger">Last Wicket</td>
                                                <td colSpan={4}>{matchLiveData?.lastwicket ? matchLiveData?.lastwicket.player + ' ' + matchLiveData?.lastwicket.run + '(' + matchLiveData?.lastwicket.ball + ')' : ''} </td>
                                            </tr>
                                            <tr>
                                                <td className="tx-primary">Next Bastman: </td>
                                                <td colSpan={5}>{matchLiveData?.next_batsman ? matchLiveData?.next_batsman : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="tx-primary" colSpan={5}>
                                                    Yet to Bat: {matchLiveData?.yet_to_bet && matchLiveData?.yet_to_bet.length > 0 ? matchLiveData.yet_to_bet.join(', ') : '-'}
                                                </td>
                                            </tr> 
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="score-table-section mb-15">
                                    <table className="score-table">
                                        <thead>
                                            <tr>
                                                <th className="tx-primary">Bowler</th>
                                                <th className="text-center">Ovr</th>
                                                <th className="text-center">M</th>
                                                <th className="text-center">R</th>
                                                <th className="text-center">W</th>
                                                <th className="text-center">ECO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {matchLiveData?.bolwer ?
                                                <tr>
                                                    <td>{matchLiveData?.bolwer.name}</td>
                                                    <td className="text-center">{matchLiveData?.bolwer.over}</td>
                                                    <td className="text-center">0</td>
                                                    <td className="text-center">{matchLiveData?.bolwer.run}</td>
                                                    <td className="text-center">{matchLiveData?.bolwer.wicket}</td>
                                                    <td className="text-center">{matchLiveData?.bolwer.economy}</td>
                                                </tr>
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cardBoxBody mb-15">
                                    <div className="hmatchbox">
                                        <div className="hmatchbox-left">
                                            <div className="hmtmatch">
                                                <div className="hmtmatchMedia">
                                                    <img src={matchLiveData && matchLiveData.team_a_img ? matchLiveData.team_a_img : constant.ICON_DEFAULT_IMAGE} alt={matchLiveData && matchLiveData.team_a_short ? matchLiveData.team_a_short : ''} onError={handleImageError}></img>
                                                </div>
                                                <img src={matchLiveData.team_a_img && matchLiveData.team_a_img ? matchLiveData.team_a_img : constant.ICON_DEFAULT_IMAGE} className="bimg" alt={matchLiveData && matchLiveData.team_a_short ? matchLiveData.team_a_short : ''} onError={handleImageError}></img>
                                                <div className="hmtmatchContent">
                                                    <h6 className="hmtmatch-name">{matchLiveData.team_a_short}</h6>
                                                    {matchLiveData.team_a_scores || matchLiveData.team_a_over ?
                                                        <div className="hmtmatch-score">
                                                            <span className="score">{matchLiveData.team_a_scores}</span>
                                                            <span className="over">({matchLiveData.team_a_over} ov)</span>
                                                        </div>
                                                        :
                                                        <div className="hmtmatch-score">
                                                            <span className="score">Yet to Bat</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hmatchbox-right justify-content-end">
                                            <div className="hmtmatch">
                                                <img src={matchLiveData && matchLiveData.team_b_img ? matchLiveData.team_b_img : constant.ICON_DEFAULT_IMAGE} className="cimg" alt={matchLiveData.team_b_short && matchLiveData.team_b_short ? matchLiveData.team_b_short : 'Hero Live Line'} onError={handleImageError}></img>
                                                <div className="hmtmatchContent tx-right">
                                                    <h6 className="hmtmatch-name">{matchLiveData.team_b_short}</h6>
                                                    {matchLiveData.team_b_scores || matchLiveData.team_b_over ?
                                                        <div className="hmtmatch-score">
                                                            <span className="score">{matchLiveData.team_b_scores}</span>
                                                            <span className="over">({matchLiveData.team_b_over} ov)</span>
                                                        </div>
                                                        :
                                                        <div className="hmtmatch-score">
                                                            <span className="score">Yet to Bat</span>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="hmtmatchMedia">
                                                    <img src={matchLiveData && matchLiveData.team_b_img ? matchLiveData.team_b_img : constant.ICON_DEFAULT_IMAGE} alt={matchLiveData.team_b_short && matchLiveData.team_b_short ? matchLiveData.team_b_short : 'Hero Live Line'} onError={handleImageError}></img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="score-table-section mb-15">
                                    <table className="score-table">
                                        <thead>
                                            <tr>
                                                <th className="tx-primary">Run Rate</th>
                                                <th className="text-center">13.10*</th>
                                                <th className="text-center">14</th>
                                                <th className="text-center">15</th>
                                                <th className="text-center">16</th>
                                                <th className="text-center">17</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>20 Over</td>
                                                <td className="text-center">262</td>
                                                <td className="text-center">262</td>
                                                <td className="text-center">262</td>
                                                <td className="text-center">262</td>
                                                <td className="text-center">262</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="cardBoxBody mb-15">
                                    <div className="hmatchbox">
                                        <div className="hmatchbox-left">
                                            <div className="hmtmatch">
                                                <div className="hmtmatchContent">
                                                    <h6 className="hmtmatch-name">{matchLiveData.team_a_short}</h6>
                                                    {matchLiveData.team_a_scores || matchLiveData.team_a_over ?
                                                        <div className="hmtmatch-score">
                                                            <span className="score">{matchLiveData.team_a_scores}</span>
                                                        </div>
                                                        :
                                                        <div className="hmtmatch-score">
                                                            <span className="score">Yet to Bat</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hmatchbox-center">
                                            <div>
                                                <p className="mb-0 tx-primary text-center"><i className="ri-wireless-charging-line"></i></p>
                                                <p className="mb-0 tx-primary">After 20.0 Overs</p>
                                            </div>
                                        </div>
                                        <div className="hmatchbox-right justify-content-end">
                                            <div className="hmtmatch">
                                                <div className="hmtmatchContent tx-right">
                                                    <h6 className="hmtmatch-name">{matchLiveData.team_b_short}</h6>
                                                    {matchLiveData.team_b_scores || matchLiveData.team_b_over ?
                                                        <div className="hmtmatch-score">
                                                            <span className="score">{matchLiveData.team_b_scores}</span>
                                                        </div>
                                                        :
                                                        <div className="hmtmatch-score">
                                                            <span className="score">Yet to Bat</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="row"> 
                            <div className="col-lg-12">
                                <div className="cardBoxBody mb-15">
                                    <div className="hmatchbox">
                                        <div className="hmatchbox-left">
                                            <div className="hmtmatch" style={{ 'width': '100%' }}>
                                                <div className="hmtmatchMedia">
                                                    <img src={matchLiveData && matchLiveData.team_a_img ? matchLiveData.team_a_img : constant.ICON_DEFAULT_IMAGE} alt={matchLiveData && matchLiveData.team_a_short ? matchLiveData.team_a_short: 'Hero Live Line'} onError={handleImageError}></img>
                                                </div>
                                                <div className="hmtmatchContent">
                                                    <h6 className="hmtmatch-name">{matchLiveData.team_a_short}:</h6>
                                                </div>
                                                <div className="wininlayb">
                                                    <span className="run">2</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hmatchbox-right justify-content-end">
                                            <div className="hmtmatch" style={{ 'width': '100%' }}>
                                                <div className="hmtmatchMedia">
                                                    <img src={matchLiveData && matchLiveData.team_b_img ? matchLiveData.team_b_img : constant.ICON_DEFAULT_IMAGE} alt={matchLiveData.team_b_short && matchLiveData.team_b_short ? matchLiveData.team_b_short : 'Hero Live Line'} onError={handleImageError}></img>
                                                </div>
                                                <div className="hmtmatchContent tx-right">
                                                    <h6 className="hmtmatch-name">{matchLiveData.team_b_short}:</h6>
                                                </div>
                                                <div className="wininlayb">
                                                    <span className="ball">2</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="score-table-section mb-15">
                                    <table className="score-table">
                                        <thead>
                                            <tr>
                                                <th className="tx-primary">Session</th>
                                                <th className="text-center">Min</th>
                                                <th className="text-center">Max</th>
                                                <th className="text-center">Open</th>
                                                <th className="text-center">Pass</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>6 Over</td>
                                                <td className="text-center">51</td>
                                                <td className="text-center">62</td>
                                                <td className="text-center">52</td>
                                                <td className="text-center" style={{ "color": '#00AA01' }}>54</td>
                                            </tr>
                                            <tr>
                                                <td>6 Over</td>
                                                <td className="text-center">51</td>
                                                <td className="text-center">62</td>
                                                <td className="text-center">52</td>
                                                <td className="text-center" style={{ "color": '#B70000' }}>54</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="score-table-section mb-15">
                                    <table className="score-table">
                                        <thead>
                                            <tr>
                                                <th className="tx-primary">Session</th>
                                                <th className="text-center">Min</th>
                                                <th className="text-center">Max</th>
                                                <th className="text-center">Open</th>
                                                <th className="text-center">Pass</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>6 Over</td>
                                                <td className="text-center">51</td>
                                                <td className="text-center">62</td>
                                                <td className="text-center">52</td>
                                                <td className="text-center" style={{ "color": '#00AA01' }}>54</td>
                                            </tr>
                                            <tr>
                                                <td>6 Over</td>
                                                <td className="text-center">51</td>
                                                <td className="text-center">62</td>
                                                <td className="text-center">52</td>
                                                <td className="text-center" style={{ "color": '#B70000' }}>54</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default MatchLiveLine
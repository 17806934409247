import { useContext, useEffect, useRef, useState } from "react";
import { ApiService } from "../Services/apiservices";
import DataContext from "../Elements/event_context";

const Footer = () => {
    const didMountRef = useRef(true)
    const contextValues = useContext(DataContext);
    const [footerDesc, setFooterDesc] = useState({});

    useEffect(() => {
        if (didMountRef.current) {
            getFooterData()
        }
        didMountRef.current = false
    })

    const getFooterData = () => {
        ApiService.fetchData("footerList").then((res) => {
            if (res.status === "success") {
                setFooterDesc(res.footerData);
            }
        })
    }
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <a href="/" className="footer-logo mb-30">
                                <img src="/img/logo.png" alt="" width={120} height={65}></img>
                            </a>
                            <p dangerouslySetInnerHTML={{ __html: footerDesc.footer_desc1 }}></p>
                        </div>
                        <div className="col-lg-2" dangerouslySetInnerHTML={{ __html: footerDesc.footer_desc2 }}></div>
                        <div className="col-lg-3">
                            <h2 className="footer-title">Download the app</h2>
                            {contextValues.settingData.playstore_link && (<a href={contextValues.settingData.playstore_link} target='_blank' className="download-button">
                                <img src="/img/google-play.png"></img>
                            </a>)}
                            {contextValues.settingData.appstore_link && (<a href={contextValues.settingData.appstore_link} target='_blank' className="download-button">
                                <img src="/img/app-store.png"></img>
                            </a>)}
                        </div>
                        <div className="col-lg-3">
                            <h2 className="footer-title">Connect with us</h2>
                            <ul className="footer-social">
                                {contextValues.settingData.facebook_url && (
                                    <li>
                                        <a href={contextValues.settingData.facebook_url} target="_blank">
                                            <i className="ri-facebook-fill"></i>
                                        </a>
                                    </li>
                                )}
                                {contextValues.settingData.youtube_url && (
                                    <li>
                                        <a href={contextValues.settingData.youtube_url} target="_blank">
                                            <i className="ri-youtube-line"></i>
                                        </a>
                                    </li>
                                )}
                                {contextValues.settingData.instagram_url && (
                                    <li>
                                        <a href={contextValues.settingData.instagram_url} target="_blank">
                                            <i className="ri-instagram-line"></i>
                                        </a>
                                    </li>
                                )}
                                {contextValues.settingData.telegram_url && (
                                    <li>
                                        <a href={contextValues.settingData.telegram_url} target="_blank">
                                            <i className="ri-telegram-line"></i>
                                        </a>
                                    </li>
                                )} 
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright">
                Copyright @ 2024 | All Right Reserved | Hero Live Line
            </div>
        </>
    )
}
export default Footer
import constant from "../../../Component/Services/constant";
import { useEffect, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import { useParams } from "react-router-dom"
import Skeleton from 'react-loading-skeleton'

const MatchInfo = ({ matchDetail, activeTabParent }) => {

    const [headMatchCompareActiveTab, setHeadMatchCompareActiveTab] = useState('ALL');
    const [headToHeadMatchCompareInfo, setHeadToHeadMatchCompareInfo] = useState({});
    const [headTossCompareActiveTab, setHeadTossCompareActiveTab] = useState('ALL');
    const [headToHeadTossCompareInfo, setHeadToHeadTossCompareInfo] = useState({});

    const { match_id } = useParams()
    const [loading, setLoading] = useState(true);
    const [HeadMatchLoading, setHeadMatchLoading] = useState(true);

    useEffect(() => {
        if ((match_id && activeTabParent === 'Info')) {
            headToHeadTossCompare(headTossCompareActiveTab);
            headToHeadMatchCompare(headMatchCompareActiveTab);
        }
        if (matchDetail && matchDetail.match_teama_id && matchDetail.match_teamb_id) {
            TeamTossCompare(matchDetail.match_teama_id, matchDetail.match_teamb_id, teamTossCompareActiveTab)
            Last5MatchTeamForm(matchDetail.match_teama_id, matchDetail.match_teamb_id, last5MatchTeamFormActiveTab);
            TeamCompareLast10Matches(matchDetail.match_teama_id, matchDetail.match_teamb_id, teamCompareLast10MatchesActiveTab);
            HeadToHead(matchDetail.match_teama_id, matchDetail.match_teamb_id, headToHeadActiveTab);
        }
    }, [match_id, activeTabParent, matchDetail]);

    const headToHeadTossCompare = (headTossCompareActiveTab) => {
        setLoading(true);
        ApiService.fetchData("headToHeadTossCompare/" + match_id + '/' + headTossCompareActiveTab)
            .then((res) => {
                if (res.status == 'success') {
                    setHeadToHeadTossCompareInfo(res.tossCompareResults);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const headToHeadMatchCompare = (headMatchCompareActiveTab) => {
        setHeadMatchLoading(true);
        ApiService.fetchData("headToHeadMatchCompare/" + match_id + '/' + headMatchCompareActiveTab)
            .then((res) => {
                if (res.status == 'success') {
                    setHeadToHeadMatchCompareInfo(res.matchCompareResults);
                }
                setHeadMatchLoading(false);
            })
            .catch(() => {
                setHeadMatchLoading(false);
            });
    };

    const handleImageError = (event) => {
        event.target.src = constant.ICON_DEFAULT_IMAGE;
    };

    const handleTabClick = (tab) => {
        setHeadTossCompareActiveTab(tab);
        headToHeadTossCompare(tab);
    };

    const handleMatchTabClick = (tab) => {
        setHeadMatchCompareActiveTab(tab);
        headToHeadMatchCompare(tab);
    };

    /* Team Toss Compare */
    const [teamTossCompareActiveTab, setTeamTossCompareActiveTab] = useState('1');
    const [teamTossCompareInfo, setTeamTossCompareInfo] = useState({});
    const [teamTossCompareLoading, setTeamTossCompareLoading] = useState(true);
    const handleTeamTossCompareTabClick = (match_teama_id, match_teamb_id, tab) => {
        setTeamTossCompareActiveTab(tab);
        TeamTossCompare(match_teama_id, match_teamb_id, tab);
    };

    const TeamTossCompare = (match_teama_id, match_teamb_id, teamTossCompareActiveTab) => {
        setTeamTossCompareLoading(true);
        const formData = new FormData();
        formData.append('team_a_id', match_teama_id);
        formData.append('team_b_id', match_teamb_id);
        formData.append('match_type', teamTossCompareActiveTab);
        ApiService.postCricketData("tossComparisonByTeamId/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        setTeamTossCompareInfo(res.data)
                    }
                }
                setTimeout(() => {
                    setTeamTossCompareLoading(false);
                }, 500);
            });
    };

    /* Last 5 Match Team Form */
    const [last5MatchTeamFormActiveTab, setLast5MatchTeamFormActiveTab] = useState('1');
    const [last5MatchTeamFormInfo, setLast5MatchTeamFormInfo] = useState({});
    const [last5MatchTeamFormLoading, setLast5MatchTeamFormLoading] = useState(true);
    const handleLast5MatchTeamFormTabClick = (match_teama_id, match_teamb_id, tab) => {
        setLast5MatchTeamFormActiveTab(tab);
        Last5MatchTeamForm(match_teama_id, match_teamb_id, tab);
    };

    const Last5MatchTeamForm = (match_teama_id, match_teamb_id, last5MatchTeamFormActiveTab) => {
        setLast5MatchTeamFormLoading(true);
        const formData = new FormData();
        formData.append('team_a_id', match_teama_id);
        formData.append('team_b_id', match_teamb_id);
        formData.append('match_type', last5MatchTeamFormActiveTab);
        ApiService.postCricketData("teamFormByTeamId/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        setLast5MatchTeamFormInfo(res.data)
                    }
                }
                setTimeout(() => {
                    setLast5MatchTeamFormLoading(false);
                }, 500);
            });
    };

    /* Team Compare Last 10 Matches */
    const [teamCompareLast10MatchesActiveTab, setTeamCompareLast10MatchesActiveTab] = useState('1');
    const [teamCompareLast10MatchesInfo, setTeamCompareLast10MatchesInfo] = useState({});
    const [teamCompareLast10MatchesLoading, setTeamCompareLast10MatchesLoading] = useState(true);
    const handleTeamCompareLast10MatchesTabClick = (match_teama_id, match_teamb_id, tab) => {
        setTeamCompareLast10MatchesActiveTab(tab);
        TeamCompareLast10Matches(match_teama_id, match_teamb_id, tab);
    };

    const TeamCompareLast10Matches = (match_teama_id, match_teamb_id, teamCompareLast10MatchesActiveTab) => {
        setTeamCompareLast10MatchesLoading(true);
        const formData = new FormData();
        formData.append('team_a_id', match_teama_id);
        formData.append('team_b_id', match_teamb_id);
        formData.append('match_type', teamCompareLast10MatchesActiveTab);
        ApiService.postCricketData("teamComparisonByTeamId/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        setTeamCompareLast10MatchesInfo(res.data)
                    }
                }
                setTimeout(() => {
                    setTeamCompareLast10MatchesLoading(false);
                }, 500);
            });
    };

    /* Head To Head */
    const [headToHeadActiveTab, setHeadToHeadActiveTab] = useState('1');
    const [headToHeadInfo, setHeadToHeadInfo] = useState({});
    const [headToHeadLoading, setHeadToHeadLoading] = useState(true);
    const handleHeadToHeadTabClick = (match_teama_id, match_teamb_id, tab) => {
        setHeadToHeadActiveTab(tab);
        HeadToHead(match_teama_id, match_teamb_id, tab);
    };

    const HeadToHead = (match_teama_id, match_teamb_id, headToHeadActiveTab) => {
        setHeadToHeadLoading(true);
        const formData = new FormData();
        formData.append('team_a_id', match_teama_id);
        formData.append('team_b_id', match_teamb_id);
        formData.append('match_type', headToHeadActiveTab);
        ApiService.postCricketData("headToHeadByTeamId/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        console.log('res.data', res.data);

                        setHeadToHeadInfo(res.data)
                    }
                }
                setTimeout(() => {
                    setHeadToHeadLoading(false);
                }, 500);
            });
    };

    return (
        <>
            {activeTabParent === 'Info' && (
                <>
                    {/* Info */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Info</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="row">
                                <div className="col-lg-6 border-right">
                                    <div className="list-box">
                                        <ul>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/01.png"></img>
                                                    <span className="tx-gray-500">Match</span>
                                                </div>
                                                <span>{matchDetail.match_title}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/00.png"></img>
                                                    <span className="tx-gray-500">Series</span>
                                                </div>
                                                <span>{matchDetail.match_series}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/02.png"></img>
                                                    <span className="tx-gray-500">Date</span>
                                                </div>
                                                <span>{matchDetail.match_date_wise}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/03.png"></img>
                                                    <span className="tx-gray-500">Time</span>
                                                </div>
                                                <span>{matchDetail.match_match_time}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/04.png"></img>
                                                    <span className="tx-gray-500">Toss</span>
                                                </div>
                                                <span>{matchDetail.match_toss ? matchDetail.match_toss : '-----'}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/05.png"></img>
                                                    <span className="tx-gray-500">Venue</span>
                                                </div>
                                                <span>{matchDetail.match_venue_name}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="list-box pl-15">
                                        <ul>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/06.png"></img>
                                                    <span className="tx-gray-500">Umpire</span>
                                                </div>
                                                <span>{matchDetail.match_umpire}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/06.png"></img>
                                                    <span className="tx-gray-500">Third Umpire</span>
                                                </div>
                                                <span>{matchDetail.match_third_umpire}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/07.png"></img>
                                                    <span className="tx-gray-500">Referee</span>
                                                </div>
                                                <span>{matchDetail.match_referee}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/08.png"></img>
                                                    <span className="tx-gray-500">Player O.T.M</span>
                                                </div>
                                                <span>{matchDetail.match_player_otm ? matchDetail.match_player_otm : '-----'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Venue */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Venue</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="row">
                                <div className="col-lg-6 border-right">
                                    <div className="list-box">
                                        <ul>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/09.png"></img>
                                                    <span className="tx-gray-500">Stadium</span>
                                                </div>
                                                <span>{matchDetail.match_stadium ? matchDetail.match_stadium : '-----'}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/10.png"></img>
                                                    <span className="tx-gray-500">City</span>
                                                </div>
                                                <span>{matchDetail.match_venue_location ? matchDetail.match_venue_location : '-----'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="list-box pl-15">
                                        <ul>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/11.png"></img>
                                                    <span className="tx-gray-500">Capacity</span>
                                                </div>
                                                <span>{matchDetail.match_capacity ? matchDetail.match_capacity : '-----'}</span>
                                            </li>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/12.png"></img>
                                                    <span className="tx-gray-500">Ends</span>
                                                </div>
                                                <span>{matchDetail.match_ends ? matchDetail.match_ends : '-----'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* TV Guide In */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">TV Guide In</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="row">
                                <div className="col-lg-6 border-right">
                                    <div className="list-box">
                                        <ul>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/13.png"></img>
                                                    <span className="tx-gray-500">Streaming</span>
                                                </div>
                                                <span>{matchDetail.match_streaming ? matchDetail.match_streaming : '-----'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="list-box pl-15">
                                        <ul>
                                            <li>
                                                <div className="lbic">
                                                    <img src="/img/14.png"></img>
                                                    <span className="tx-gray-500">TV</span>
                                                </div>
                                                <span>{matchDetail.match_tv ? matchDetail.match_tv : '-----'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Head to Head Toss Compare */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Head to Head Toss Compare</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="ctabs mb-15">
                                <ul>
                                    {['ALL', 'T20', 'ODI', 'Test'].map((tab) => (
                                        <li
                                            key={tab}
                                            className={headTossCompareActiveTab === tab ? 'active' : ''}
                                            onClick={() => handleTabClick(tab)}
                                        >
                                            {tab === 'ALL' ? 'All' : tab === 'Test' ? 'Test' : tab + "'s"}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_shortname} onError={handleImageError} />
                                    <h6 className="mb-0 tx-14">{matchDetail.match_teama_shortname}</h6>
                                </div>
                                {loading ? (

                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {headToHeadTossCompareInfo && headToHeadTossCompareInfo.teamA && headToHeadTossCompareInfo.teamA.length > 0 && headToHeadTossCompareInfo.teamA.map((result, index) => (
                                                    <li key={index} className={result}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_shortname} onError={handleImageError} />
                                    <h6 className="mb-0 tx-14">{matchDetail.match_teamb_shortname}</h6>
                                </div>
                                {loading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {headToHeadTossCompareInfo && headToHeadTossCompareInfo.teamB && headToHeadTossCompareInfo.teamB.length > 0 && headToHeadTossCompareInfo.teamB.map((result, index) => (
                                                    <li key={index} className={result}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Team Toss Compare */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Team Toss Compare</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="ctabs mb-15">
                                <ul>
                                    <li className={teamTossCompareActiveTab === '1' ? 'active' : ''} onClick={() => handleTeamTossCompareTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '1')}>ODI's</li>
                                    <li className={teamTossCompareActiveTab === '2' ? 'active' : ''} onClick={() => handleTeamTossCompareTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '2')}>T20's</li>
                                    <li className={teamTossCompareActiveTab === '3' ? 'active' : ''} onClick={() => handleTeamTossCompareTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '3')}>Test</li>
                                </ul>
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={teamTossCompareInfo && teamTossCompareInfo.team_a && teamTossCompareInfo.team_a.flag ? teamTossCompareInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={teamTossCompareInfo && teamTossCompareInfo.team_a && teamTossCompareInfo.team_a.short_name ? teamTossCompareInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError}></img>
                                    <h6 className="mb-0 tx-14">{teamTossCompareInfo && teamTossCompareInfo.team_a && teamTossCompareInfo.team_a.short_name ? teamTossCompareInfo.team_a.short_name : 'Hero Live Line'}</h6>
                                </div>
                                {teamTossCompareLoading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {teamTossCompareInfo && teamTossCompareInfo.team_a && teamTossCompareInfo.team_a.toss.length > 0 && teamTossCompareInfo.team_a.toss.map((result, index) => (
                                                    <li key={index} className={result == 'L' ? 'loose' : 'win'}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={teamTossCompareInfo && teamTossCompareInfo.team_b && teamTossCompareInfo.team_b.flag ? teamTossCompareInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={teamTossCompareInfo && teamTossCompareInfo.team_b && teamTossCompareInfo.team_b.short_name ? teamTossCompareInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError}></img>
                                    <h6 className="mb-0 tx-14">{teamTossCompareInfo && teamTossCompareInfo.team_b && teamTossCompareInfo.team_b.short_name ? teamTossCompareInfo.team_b.short_name : 'Hero Live Line'}</h6>
                                </div>
                                {teamTossCompareLoading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {teamTossCompareInfo && teamTossCompareInfo.team_b && teamTossCompareInfo.team_b.toss.length > 0 && teamTossCompareInfo.team_b.toss.map((result, index) => (
                                                    <li key={index} className={result == 'L' ? 'loose' : 'win'}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Head to Head Match Compare */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Head to Head Match Compare</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="ctabs mb-15">
                                <ul>
                                    {['ALL', 'T20', 'ODI', 'Test'].map((tab) => (
                                        <li
                                            key={tab}
                                            className={headMatchCompareActiveTab === tab ? 'active' : ''}
                                            onClick={() => handleMatchTabClick(tab)}
                                        >
                                            {tab === 'ALL' ? 'All' : tab === 'Test' ? 'Test' : tab + "'s"}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={matchDetail.match_teama_image} alt={matchDetail.match_teama_shortname} onError={handleImageError}></img>
                                    <h6 className="mb-0 tx-14">{matchDetail.match_teama_shortname}</h6>
                                </div>
                                {HeadMatchLoading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {headToHeadMatchCompareInfo && headToHeadMatchCompareInfo.teamA && headToHeadMatchCompareInfo.teamA.length > 0 && headToHeadMatchCompareInfo.teamA.map((result, index) => (
                                                    <li key={index} className={result}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={matchDetail.match_teamb_image} alt={matchDetail.match_teamb_shortname} onError={handleImageError}></img>
                                    <h6 className="mb-0 tx-14">{matchDetail.match_teamb_shortname}</h6>
                                </div>
                                {HeadMatchLoading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {headToHeadMatchCompareInfo && headToHeadMatchCompareInfo.teamB && headToHeadMatchCompareInfo.teamB.length > 0 && headToHeadMatchCompareInfo.teamB.map((result, index) => (
                                                    <li key={index} className={result}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Last 5 Match Team Form */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Last 5 Match Team Form</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="ctabs mb-15">
                                <ul>
                                    <li className={last5MatchTeamFormActiveTab === '1' ? 'active' : ''} onClick={() => handleLast5MatchTeamFormTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '1')}>ODI's</li>
                                    <li className={last5MatchTeamFormActiveTab === '2' ? 'active' : ''} onClick={() => handleLast5MatchTeamFormTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '2')}>T20's</li>
                                    <li className={last5MatchTeamFormActiveTab === '3' ? 'active' : ''} onClick={() => handleLast5MatchTeamFormTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '3')}>Test</li>
                                </ul>
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_a && last5MatchTeamFormInfo.team_a.flag ? last5MatchTeamFormInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_a && last5MatchTeamFormInfo.team_a.short_name ? last5MatchTeamFormInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError}></img>
                                    <h6 className="mb-0 tx-14">{last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_a && last5MatchTeamFormInfo.team_a.short_name ? last5MatchTeamFormInfo.team_a.short_name : 'Hero Live Line'}</h6>
                                </div>
                                {last5MatchTeamFormLoading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_a && last5MatchTeamFormInfo.team_a.forms.length > 0 && last5MatchTeamFormInfo.team_a.forms.map((result, index) => (
                                                    <li key={index} className={result == 'L' ? 'loose' : 'win'}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="htosbox mt-20">
                                <div className="htosbox-team">
                                    <img src={last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_b && last5MatchTeamFormInfo.team_b.flag ? last5MatchTeamFormInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_b && last5MatchTeamFormInfo.team_b.short_name ? last5MatchTeamFormInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError}></img>
                                    <h6 className="mb-0 tx-14">{last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_b && last5MatchTeamFormInfo.team_b.short_name ? last5MatchTeamFormInfo.team_b.short_name : 'Hero Live Line'}</h6>
                                </div>
                                {last5MatchTeamFormLoading ? (
                                    <div className="htosbox-result">
                                        <ul>
                                            {[...Array(5)].map((_, index) => (
                                                <li className="star" key={index}>
                                                    <Skeleton circle={true} width={30} height={30} />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <>
                                        <div className="htosbox-result">
                                            <ul>
                                                <li className="star">
                                                    <i className="ri-star-fill"></i>
                                                </li>
                                                {last5MatchTeamFormInfo && last5MatchTeamFormInfo.team_b && last5MatchTeamFormInfo.team_b.forms.length > 0 && last5MatchTeamFormInfo.team_b.forms.map((result, index) => (
                                                    <li key={index} className={result == 'L' ? 'loose' : 'win'}>
                                                        {result.charAt(0).toUpperCase()}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* Team Compare Last 10 Matches */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Team Compare Last 10 Matches</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="ctabs mb-15">
                                <ul>
                                    <li className={teamCompareLast10MatchesActiveTab === '1' ? 'active' : ''} onClick={() => handleTeamCompareLast10MatchesTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '1')}>ODI's</li>
                                    <li className={teamCompareLast10MatchesActiveTab === '2' ? 'active' : ''} onClick={() => handleTeamCompareLast10MatchesTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '2')}>T20's</li>
                                    <li className={teamCompareLast10MatchesActiveTab === '3' ? 'active' : ''} onClick={() => handleTeamCompareLast10MatchesTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '3')}>Test</li>
                                </ul>
                            </div>
                            <div class="hmatchbox">
                                <div class="hmatchbox-left">
                                    <div class="hmtmatch">
                                        <div class="hmtmatchMedia"><img src={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo.team_a.flag ? teamCompareLast10MatchesInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo.team_a.short_name ? teamCompareLast10MatchesInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError} /></div>
                                        <img src={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo.team_a.flag ? teamCompareLast10MatchesInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo.team_a.short_name ? teamCompareLast10MatchesInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError} class="bimg" />
                                        <div class="hmtmatchContent">
                                            <h6 class="hmtmatch-name">{teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo.team_a.short_name ? teamCompareLast10MatchesInfo.team_a.short_name : ''}</h6>
                                            <div class="hmtmatch-score">vs all team</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hmatchbox-right justify-content-end">
                                    <div class="hmtmatch">
                                        <img src={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo.team_b.flag ? teamCompareLast10MatchesInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo.team_b.short_name ? teamCompareLast10MatchesInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError} class="cimg" />
                                        <div class="hmtmatchContent tx-right">
                                            <h6 class="hmtmatch-name">{teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo.team_b.short_name ? teamCompareLast10MatchesInfo.team_b.short_name : ''}</h6>
                                            <div class="hmtmatch-score">vs all team</div>
                                        </div>
                                        <div class="hmtmatchMedia"><img src={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo.team_b.flag ? teamCompareLast10MatchesInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo.team_b.short_name ? teamCompareLast10MatchesInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError} /></div>
                                    </div>
                                </div>
                            </div>
                            <div class="tcllist">
                                <ul>
                                    <li>
                                        <span>10</span>
                                        <span class="name">Matches Played</span>
                                        <span>10</span>
                                    </li>
                                    <li>
                                        <span>{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo.team_a.win >= 0) ? teamCompareLast10MatchesInfo.team_a.win : '0'}</span>
                                        <span class="name">Win</span>
                                        <span>{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo?.team_b.win >= 0) ? teamCompareLast10MatchesInfo?.team_b.win : '0'}</span>
                                    </li>
                                    <li>
                                        <span>{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo?.team_a.avg_score >= 0) ? teamCompareLast10MatchesInfo?.team_a.avg_score : '0'}</span>
                                        <span class="name">Average Score</span>
                                        <span>{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo?.team_b.avg_score >= 0) ? teamCompareLast10MatchesInfo?.team_b.avg_score : '0'}</span>
                                    </li>
                                    <li>
                                        <span class="tx-green">{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo?.team_a.high_score >= 0) ? teamCompareLast10MatchesInfo?.team_a.high_score : '0'}</span>
                                        <span class="name">Highest Score</span>
                                        <span class="tx-green">{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo?.team_b.high_score >= 0) ? teamCompareLast10MatchesInfo?.team_b.high_score : '0'}</span>
                                    </li>
                                    <li>
                                        <span class="tx-red">{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_a && teamCompareLast10MatchesInfo?.team_a.low_score >= 0) ? teamCompareLast10MatchesInfo?.team_a.low_score : '0'}</span>
                                        <span class="name">Lowest Score</span>
                                        <span class="tx-red">{(teamCompareLast10MatchesInfo && teamCompareLast10MatchesInfo.team_b && teamCompareLast10MatchesInfo?.team_b.low_score >= 0) ? teamCompareLast10MatchesInfo?.team_b.low_score : '0'}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Head To Head */}
                    <div className="mb-20">
                        <div className="section-title">
                            <h5 className="mb-0">Head To Head</h5>
                        </div>
                        <div className="cardBox p-15">
                            <div className="ctabs mb-15">
                                <ul>
                                    <li className={headToHeadActiveTab === '1' ? 'active' : ''} onClick={() => handleHeadToHeadTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '1')}>ODI's</li>
                                    <li className={headToHeadActiveTab === '2' ? 'active' : ''} onClick={() => handleHeadToHeadTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '2')}>T20's</li>
                                    <li className={headToHeadActiveTab === '3' ? 'active' : ''} onClick={() => handleHeadToHeadTabClick(matchDetail.match_teama_id, matchDetail.match_teamb_id, '3')}>Test</li>
                                </ul>
                            </div>
                            <div class="hmatchbox">
                                <div class="hmatchbox-left">
                                    <div class="hmtmatch">
                                        <div class="hmtmatchMedia"><img src={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.flag ? headToHeadInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.short_name ? headToHeadInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError} /></div>
                                        <img src={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.flag ? headToHeadInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.short_name ? headToHeadInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError} class="bimg" />
                                        <div class="hmtmatchContent">
                                            <h6 class="hmtmatch-name">{headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.short_name ? headToHeadInfo.team_a.short_name : ''}</h6>
                                            <div class="hmtmatch-score">vs all team</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="hmatchbox-right justify-content-end">
                                    <div class="hmtmatch">
                                        <img src={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.flag ? headToHeadInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.short_name ? headToHeadInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError} class="cimg" />
                                        <div class="hmtmatchContent tx-right">
                                            <h6 class="hmtmatch-name">{headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.short_name ? headToHeadInfo.team_b.short_name : ''}</h6>
                                            <div class="hmtmatch-score">vs all team</div>
                                        </div>
                                        <div class="hmtmatchMedia"><img src={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.flag ? headToHeadInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.short_name ? headToHeadInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError} /></div>
                                    </div>
                                </div>
                            </div>
                            {headToHeadInfo && headToHeadInfo.matches && headToHeadInfo.matches.length > 0 && headToHeadInfo.matches.map((values, index) => (
                                <div class="matchCard" key={index}>
                                    <div class="matchCardHeader">
                                        <div class="left"><span class="seriesname">{values.matchs}</span></div>
                                    </div>
                                    <div class="matchCardMiddle">
                                        <div class="matchCardMiddleLeft">
                                            <div class="matchTeam">
                                                <figure class="matchTeamMedia"><img src={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.flag ? headToHeadInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.short_name ? headToHeadInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError} /></figure>
                                                <img src={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.flag ? headToHeadInfo.team_a.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.short_name ? headToHeadInfo.team_a.short_name : 'Hero Live Line'} onError={handleImageError} class="bimg" />
                                                <h6 class="mb-0 matchTeamname">{headToHeadInfo && headToHeadInfo.team_a && headToHeadInfo.team_a.short_name ? headToHeadInfo.team_a.short_name : ''}</h6>
                                                <div className="matchTeamScore">
                                                    {values.team_a_score.map((score, i) => (
                                                        <div key={i} className="matchcardteam-score">
                                                            <span className="score">{`${score.score}-${score.wicket}`}</span>
                                                            <span className="over">{`(${score.over})`}</span>
                                                            {i < values.team_a_score.length - 1 && <span> & </span>}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div class="matchTeam">
                                                <figure class="matchTeamMedia"><img src={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.flag ? headToHeadInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.short_name ? headToHeadInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError} /></figure>
                                                <img src={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.flag ? headToHeadInfo.team_b.flag : constant.ICON_DEFAULT_IMAGE} alt={headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.short_name ? headToHeadInfo.team_b.short_name : 'Hero Live Line'} onError={handleImageError} class="bimg" />
                                                <h6 class="mb-0 matchTeamname">{headToHeadInfo && headToHeadInfo.team_b && headToHeadInfo.team_b.short_name ? headToHeadInfo.team_b.short_name : ''}</h6>
                                                <div className="matchTeamScore">
                                                    {values.team_b_score.map((score, i) => (
                                                        <div key={i} className="matchcardteam-score">
                                                            <span className="score">{`${score.score}-${score.wicket}`}</span>
                                                            <span className="over">{`(${score.over})`}</span>
                                                            {i < values.team_b_score.length - 1 && <span> & </span>}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="matchCardMiddleRight">
                                            <div class="matchCardMiddleRight-inner">
                                                <div class="matchStatus">
                                                    <span class="text-success">
                                                        <p class="mb-0">{values.result}</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}

export default MatchInfo
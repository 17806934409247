import Collapse from 'react-bootstrap/Collapse';
import { useEffect, useState } from "react";
import { ApiService } from "../../../Component/Services/apiservices";
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

const MatchBallByBall = ({activeTabParent}) => {
    const { match_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [BallbyBallData, setBallbyBallData] = useState({});
    const [innings, setInnings] = useState([]);
    const [selectedTab, setSelectedTab] = useState("All");
    const [openIndexes, setOpenIndexes] = useState({}); // Updated state for collapse management
    let intervalId;
    useEffect(() => {
        if (match_id && activeTabParent === 'Ball By Ball') {
            MatchBallByBall();
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [match_id,activeTabParent]);

    const MatchBallByBall = () => {
        const dataString = { match_id: match_id };
        ApiService.postData('match/matchBallByBall', dataString).then((res) => {
            if (res.status === 'success') {
                if (res.matchOddHistoryData && res.matchOddHistoryData.match_status == 3) { 
                    const ballbyballData = res.matchOddHistoryData.match_odd_history.data;
                    const innings = Object.keys(ballbyballData);
                    setSelectedTab(innings.length > 0 ? innings[0] : null);
                    setInnings(innings);
                    setBallbyBallData(ballbyballData);
                    getLiveOddHistory(res.matchOddHistoryData.match_api_id);
                    intervalId = setInterval(() => {
                        getLiveOddHistory(res.matchOddHistoryData.match_api_id);
                    }, 1000);
                }else if (Number(res.matchOddHistoryData.match_status) == 2) {
                    const ballbyballData = res.matchOddHistoryData.match_odd_history.data;
                    const innings = Object.keys(ballbyballData);
                    setSelectedTab(innings.length > 0 ? innings[0] : null);
                    setInnings(innings);
                    setBallbyBallData(ballbyballData);
                }
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const getLiveOddHistory = (match_api_id) => {        
        const formData = new FormData();
        formData.append('match_id', match_api_id);
        ApiService.postCricketData("matchOddHistoryV1/", formData)
            .then((res) => {
                if (res.status) {
                    if (res.data) {
                        const ballbyballData = res.data;
                        const innings = Object.keys(ballbyballData);
                        setInnings(innings);
                        setBallbyBallData(ballbyballData);
                    }
                }
            })
            .catch(() => {
            });
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const toggleCollapse = (index) => {
        setOpenIndexes(prev => ({
            ...prev,
            [index]: !prev[index]
        }));
    };
    const getRunClass = (runs) => {
        switch (runs) {
            case "W":
                return "ovlisy-over ovw";   // 6 runs
            case "6":
                return "ovlisy-over ov6";   // 6 runs
            case "4":
                return "ovlisy-over ov4";   // 4 runs
            case "2":
                return "ovlisy-over ovrun"; // 2 runs
            case "WB":
                return "ovlisy-over ovwd"; 
            case "WD":
                return "ovlisy-over ovwd"; // Wide
            case "N":
                return "ovlisy-over ovn";   // No ball
            default:
                return "ovlisy-over ov"; // Default for other runs
    }
    };
    
    return (
        activeTabParent === 'Ball By Ball' && loading ? (
                <div>
                <div className="section-title">
                    <h5 className="mb-0"><Skeleton width={150} /></h5>
                </div>
                <div className="cardBox p-15 mb-20">
                    <div className="inner-tabs">
                        <ul>
                            <li className="active"><Skeleton width={100} /></li>
                            <li><Skeleton width={100} /></li>
                        </ul>
                    </div>
                </div>
                {Array.from({ length: 7 }).map((_, index) => (
                <div className="oddsbox mb-20">
                    <div className="oddsbox-header">
                        <div className="odleft">
                            <Skeleton width={150} />
                        </div>
                            <Skeleton circle={true} height={40} width={40} />
                        <div className="odright">
                            <Skeleton width={100} />
                        </div>
                    </div>
                </div>
                 ))}
            </div>
        ) : (
            activeTabParent === 'Ball By Ball' && BallbyBallData && selectedTab && BallbyBallData[selectedTab]   ? 
            <div>
                <div className="section-title">
                    <h5 className="mb-0">Ball By Ball</h5>
                </div>
                <div className="cardBox p-15 mb-20">
                    <div className="inner-tabs">
                        <ul>
                            {innings.length > 0 && innings.map((inning, index) => (
                                <li
                                    key={index}
                                    className={selectedTab === inning ? "active" : ""}
                                    onClick={() => handleTabClick(inning)}
                                >
                                    {inning + ' Innings'}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {selectedTab && BallbyBallData[selectedTab] && BallbyBallData[selectedTab].length > 0
                    ? (BallbyBallData[selectedTab]).map((teamball, index) =>
                        <div key={index} className="oddsbox mb-20">
                            <div className="oddsbox-header"
                                onClick={() => toggleCollapse(index)}
                                aria-controls={`collapse-${index}`}
                                aria-expanded={openIndexes[index] || false}
                            >
                                <div className="odleft">{teamball.team.over} Over <span className="text-success">{teamball.team.runs} Runs</span></div>
                                <i className={`ri-2x ${openIndexes[index] ? 'ri-arrow-up-circle-fill' : 'ri-arrow-down-circle-fill'}`}></i>
                                <div className="odright">{teamball.team.team} <span className="text-success">{teamball.team.score}</span></div>
                            </div>
                            {teamball.overs && teamball.overs.length > 0 && (
                                <Collapse in={openIndexes[index]}>
                                    <div className='oddsbox-body'>
                                        {teamball.overs.map((teamover, overIndex) => (
                                            <div className="oddinnerbox" key={overIndex}>
                                                <div className='oddinnerleft'>
                                                    <div className='d-flex gap-1 align-items-end mb-2'>
                                                        <span className='tx-primary tx-10'>Time</span>
                                                        <span className='tx-12'>{teamover.time}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-3'>
                                                        <span className={getRunClass(teamover.runs)}>{teamover.runs}</span>
                                                        <span className='fw700'>{teamover.score}</span>
                                                        <span className='tx-primary'>Runs</span>
                                                        <span>{teamover.overs}</span>
                                                    </div>
                                                </div>
                                                <div className='oddinnerright'>
                                                    <div className='d-flex align-items-start gap-2 mb-2'>
                                                        <span className='tx-primary tx-10'>Fav</span>
                                                        <span className='fw700'>{teamover.fav_team}</span>
                                                        <span className='back bhv'>{teamover.min_rate}</span>
                                                        <span className='lay bhv'>{teamover.max_rate}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-end gap-2'>
                                                        <span className='tx-primary tx-10'>Session</span>
                                                        <span className='session bhv'>{teamover.s_min}</span>
                                                        <span className='session bhv'>{teamover.s_max}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Collapse>
                            )}
                        </div>
                    )
                    : null}
            </div>
            :null
        )
    );
};

export default MatchBallByBall;

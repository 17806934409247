import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import constant from "../../../Component/Services/constant";
import Skeleton from 'react-loading-skeleton'
const SeriesSquads = ({ seriesDetail }) => {
    const didMountRef = useRef(true)
    const [loading, setLoading] = useState(true);
    const [playersLoading, setPlayersLoading] = useState(false);
    const [teamsData, setTeamsData] = useState([]);
    const [teamData, setTeamData] = useState({});
    const [playersData, setPlayersData] = useState([]);
    const [groupedPlayers, setGroupedPlayers] = useState({});
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            if (seriesDetail && seriesDetail.series_players && seriesDetail.series_players.data.length > 0) {
                setTeamsData(seriesDetail.series_players.data)
                setTeamData(seriesDetail.series_players.data[0].team)
                setPlayersData(seriesDetail.series_players.data[0].player)
                const grouped = seriesDetail.series_players.data[0].player.reduce((acc, player) => {
                    const { play_role } = player;
                    if (!acc[play_role]) {
                        acc[play_role] = [];
                    }
                    acc[play_role].push(player);
                    return acc;
                }, {});
                setGroupedPlayers(grouped);
            }
            setTimeout(() => {
                setLoading(false)
            }, 500);
        }
        didMountRef.current = false
    }, [seriesDetail.series_cid]);

    const handleImageError = (event) => {
        event.target.src = constant.DEFAULT_IMAGE;
    };

    const handleLink = (newsId) => {
        navigate(`/venue-detail/${newsId}`);
    };

    const setPlayers = (team, players) => {
        setPlayersLoading(true)
        setTeamData(team)
        setPlayersData(players)
        const grouped = players.reduce((acc, player) => {
            const { play_role } = player;
            if (!acc[play_role]) {
                acc[play_role] = [];
            }
            acc[play_role].push(player);
            return acc;
        }, {});
        setGroupedPlayers(grouped);
        setTimeout(() => {
            setPlayersLoading(false)
        }, 500);
    }

    const handlePlayerLink = (playerId) => {
        window.location.href = `/player-detail/${playerId}`;
    };

    return (
        <>
            {loading ?
                <div>
                    <div className="section-title">
                        <h5 className="mb-0"><Skeleton width={150} height={25} /></h5>
                    </div>
                    <div className="row g-3">
                        <div className="col-lg-4">
                            <div className="squad-sidebar">
                                {Array.from({ length: 6 }).map((_, index) => (
                                    <div className="squadbox" key={index}>
                                        <div className="squadboxMedia"><Skeleton width={50} height={50} /></div>
                                        <div className="squadboxContent">
                                            <h2 className="teamName"><Skeleton width={150} height={20} /></h2>
                                            <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={100} height={15} /></p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="squad-setion">
                                <div className="squad-setion-header">
                                    <div className="squadTeam">
                                        <div className="squadTeam-media"><Skeleton width={50} height={50} /></div>
                                        <div className="squadTeam-content">
                                            <h2 className="teamname"><Skeleton width={100} height={15} /></h2>
                                            <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={60} height={15} /></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="squad-setion-body">
                                    {Array.from({ length: 4 }).map((_, index) => (
                                        <>
                                            <div className="squad-setion-title mb-15 mt-10">
                                                <h6><Skeleton width={100} height={20} /></h6>
                                            </div>
                                            <div className="row g-3">
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <div className="col-lg-6">
                                                        <div className="squadbox squadbox-squad">
                                                            <div className="squadboxMedia"><Skeleton width={50} height={50} /></div>
                                                            <div className="squadboxContent">
                                                                <h2 className="teamName"><Skeleton width={100} height={15} /></h2>
                                                                <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={100} height={15} /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                teamsData && teamsData.length > 0 ?
                    <div>
                        <div className="section-title">
                            <h5 className="mb-0">Squads</h5>
                        </div>
                        <div className="row g-3">
                            <div className="col-lg-4">
                                <div className="squad-sidebar">
                                    {teamsData.map((teamVal, index) => (
                                        <div className="squadbox" key={index} onClick={(e) => setPlayers(teamVal.team, teamVal.player)}>
                                            <div className="squadboxMedia"><img src={teamVal.team.flag} onError={handleImageError} alt={teamVal.team.name}></img></div>
                                            <div className="squadboxContent">
                                                <h2 className="teamName">{teamVal.team.name}</h2>
                                                <p className="tx-gray-500 mb-0 tx-12">{teamVal.player.length} Players</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="squad-setion">
                                    {playersLoading ?
                                        <div className="squad-setion-header">
                                            <div className="squadTeam">
                                                <div className="squadTeam-media"><Skeleton width={50} height={50} /></div>
                                                <div className="squadTeam-content">
                                                    <h2 className="teamname"><Skeleton width={100} height={15} /></h2>
                                                    <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={60} height={15} /></p>
                                                </div>
                                            </div>
                                        </div>
                                        : <div className="squad-setion-header">
                                            <div className="squadTeam">
                                                <div className="squadTeam-media"><img src={teamData && teamData.flag ? teamData.flag : constant.DEFAULT_IMAGE} onError={handleImageError} alt={teamData && teamData.name ? teamData.name : 'Hero Live Line'}></img></div>
                                                <div className="squadTeam-content">
                                                    <h2 className="teamname">{teamData && teamData.name ? teamData.name : ''}</h2>
                                                    <p className="tx-gray-500 mb-0 tx-12">{playersData && playersData.length > 0 ? playersData.length : ''} Players</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="squad-setion-body">
                                        {playersLoading ?
                                            Array.from({ length: 4 }).map((_, index) => (
                                                <>
                                                    <div className="squad-setion-title mb-15 mt-10">
                                                        <h6><Skeleton width={100} height={20} /></h6>
                                                    </div>
                                                    <div className="row g-3">
                                                        {Array.from({ length: 4 }).map((_, index) => (
                                                            <div className="col-lg-6">
                                                                <div className="squadbox squadbox-squad">
                                                                    <div className="squadboxMedia"><Skeleton width={50} height={50} /></div>
                                                                    <div className="squadboxContent">
                                                                        <h2 className="teamName"><Skeleton width={100} height={15} /></h2>
                                                                        <p className="tx-gray-500 mb-0 tx-12"><Skeleton width={100} height={15} /></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            ))
                                            :
                                            <>
                                                {Object.keys(groupedPlayers).map((role) => (
                                                    <>
                                                        <div className="squad-setion-title mb-15 mt-10">
                                                            <h6>{role} ({groupedPlayers && groupedPlayers[role].length > 0 ? groupedPlayers[role].length : 0})</h6>
                                                        </div>

                                                        <div className="row g-3">
                                                            {groupedPlayers[role].map((player) => (
                                                                <div className="col-lg-6" onClick={(e) => handlePlayerLink(player.player_id)}>
                                                                    <div className="squadbox squadbox-squad">
                                                                        <div className="squadboxMedia"><img src={player && player.image ? player.image : constant.DEFAULT_IMAGE} onError={handleImageError} alt={player && player.name ? player.name : 'Hero Live Line'}></img></div>
                                                                        <div className="squadboxContent">
                                                                            <h2 className="teamName">{player.name}</h2>
                                                                            <p className="tx-gray-500 mb-0 tx-12">{player.play_role}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default SeriesSquads